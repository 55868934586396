.promotionSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 10px;
}

.cashnab {
  font-size: 43px;
  color: var(--promotion-title-color);
  font-weight: 700;
}

.promotionIcon {
  margin-bottom: 10px;
}

.submitjjButton {
  background-color: var(--submit-bg-color);
  color: var(--light-text-color);
  padding: 15px;
  font-family: "Montserrat", sans-serif;
  border: none;
  border-radius: 70px;
  cursor: pointer;
  width: 230px;
  margin-top: 20px;
  transition: background-color 0.3s, color 0.3s, transform 0.3s ease-in-out;
}

.submitjjButton:hover {
  background-color: var(--submit-hover-bg-color);
  transform: scale(1.08);
}

.promotionInstructions {
  text-align: left;
  margin-bottom: 20px;
  display: grid;
  grid-gap: 22px;
  margin-top: 34px;
  margin-left: 100px;
}

.promotionInstructions {
  list-style-type: none;
  /* Remove default list styling */
  counter-reset: list-counter;
  /* Initialize a counter */
}

.promotionInstructions li {
  counter-increment: list-counter;
  /* Increment the counter for each list item */
  position: relative;
  margin-bottom: 2px;
  /* Adjust as needed for spacing between items */
}

.promotionInstructions li::before {
  content: counter(list-counter) ". ";
  /* Use the counter value as the content */
  font-weight: bold;
  /* Make the number bold */
  font-size: 24px;
  /* Increase the size of the number */
  margin-right: 10px;
  /* Add space after the number */
  position: absolute;
  left: -2em;
  /* Adjust the position to create space */
  top: -6px;
}


.promotionForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
  margin-top: 20px;
}

.personalId {
  margin-bottom: 10px;
  font-size: 16px;
  color: #999;
}

.urlInput {
  width: 100%;
  padding: 15px;
  margin-bottom: 20px;
  border: 1px solid var(--input-border-color);
  border-radius: 5px;
  background-color: var(--background-color);
  color: var(--dashboard-text-color);
  font-family: "Montserrat", sans-serif;
  outline: none;
}

.submithButton {
  background-color: var(--submit-bg-color);
  color: var(--light-text-color);
  border: none;
  padding: 14px 50px;
  border-radius: 60px;
  cursor: pointer;
  font-size: 15px;
}

.gagchhg {
  font-weight: 500;
  font-size: 17px;
  font-family: Montserrat;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.372);
  /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  /* Ensure the loader is above everything else */
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #00ba8c;
  border-radius: 50%;
  width: 75px;
  height: 75px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 585px) {
  .promotionIcon {
    margin-bottom: 29px;
    width: 151px;
  }

  .cashnab {
    font-size: 22px;
    color: var(--promotion-title-color);
    font-weight: 700;
  }

  .gagchhg {
    font-size: 13px;
  }

  .promotionInstructions {
    margin-bottom: 20px;
    display: grid;
    grid-gap: 30px;
    margin-top: 30px;
    justify-content: center;
    width: 17%;
  }

  .promotionForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 271px;
    margin-top: 20px;
  }

  .submitButton {
    background-color: var(--submit-bg-color);
    color: var(--light-text-color);
    padding: 15px;
    border: none;
    border-radius: 70px;
    cursor: pointer;
    width: 135px;
    margin-top: 20px;
  }
}