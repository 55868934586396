.raiseTicketContainer {
  display: flex;
  justify-content: space-around;
  padding: 70px 55px;
  width: 100%;
  background-color: var(--background-color);
  color: var(--dashboard-text-color);
}

.poiiu {
  width: 100%;
  display: flex;
  justify-content: center;
}

.formSection {
  width: 45%;
}

.contactSection {
  width: 45%;
  text-align: left;
  margin-left: 100px;
}

.formTitle {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
}

.ticketForm {
  display: flex;
  flex-direction: column;
}

.formGroup {
  margin-bottom: 29px;
  margin-top: 5px;
}

.ticete {
  font-size: 16px;
  color: var(--form-text-color);
  margin-bottom: 5px;
  margin-top: 30px;
  font-family: "Montserrat", sans-serif;
}

.herber[type="email"],
.herer,
.hehrer {
  width: 100%;
  padding: 14px;
  outline: none;
  border-radius: 10px;
  border: 1px solid var(--form-border-color);
  margin-top: 20px;
  font-family: "Montserrat", sans-serif;
  color: var(--dashboard-text-color);
  background-color: var(--background-color);
}

.herer {
  cursor: pointer;
}

.herer option {
  cursor: pointer;
  background-color: var(--background-color);
  color: var(--dashboard-text-color);
}

.hehrer {
  height: 160px;
  resize: none;
}

.attachmentLabel {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 4px;
  padding: 8px 10px;
  border: 1px solid var(--form-border-color);
}

.fileInput {
  display: none;
}

.submithButton {
  background-color: var(--submit-bg-color);
  color: var(--card-text-color);
  padding: 15px;
  border: none;
  border-radius: 70px;
  cursor: pointer;
  width: 230px;
  margin-top: 20px;
  transition: background-color 0.3s, color 0.3s;
  font-family: "Montserrat", sans-serif;
}

.submithButton:hover {
  background-color: var(--submit-hover-bg-color);
}

.contactTitle {
  font-size: 30px;
  margin-top: 69px;
  margin-bottom: 10px;
}

.socialIcons img {
  height: 50px;
  width: 50px;
  margin-right: 10px;
}

.phone {
  font-size: 16px;
  color: var(--dashboard-text-color);
  margin-bottom: 5px;
  margin-top: 30px;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  /* Ensure the modal is above everything else */
}

.modal-content {
  background: var(--modal-bg-color);
  padding: 37px 50px;
  border-radius: 12px;
  text-align: center;
  color: var(--card-text-color);
}

.successjj {
  color: var(--success-color);
  font-size: 26px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
}

.kjhjj {
  color: var(--success-color);
  font-size: 20px;
  font-weight: 600;
  margin-top: 6px;
  font-family: "Montserrat", sans-serif;
}

.fileInputLabel {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background-color: var(--background-color);
  /* White background */
  border: 1px solid var(--form-border-color);
  /* Light grey border */
  border-radius: 10px;
  /* Rounded edges */
  font-size: 13px;
  cursor: pointer;
  /* Optional: Adds shadow for depth */
  transition: all 0.3s ease;
  font-family: "Montserrat", sans-serif;
  padding: 13px 28px;
  color: var(--dashboard-text-color);
}

.fileInputLabel:hover {
  background-color: #f8f8f8;
  /* Light grey background on hover */
}

.fileInputLabel .clipIcon {
  margin-right: 10px;
  /* Space between icon and text */
  width: 24px;
  /* Set appropriate size for your icon */
  height: auto;
}

.fileInput {
  display: none;
  /* Hide the actual input element */
}

.yuioi {
  padding: 10px 26px;
  border: none;
  border-radius: 42px;
  cursor: pointer;
  background: var(--submit-bg-color);
  color: var(--light-text-color);
  transition: background-color 0.3s, color 0.3s;
  font-family: "Montserrat", sans-serif;
  margin-top: 20px;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.372);
  /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  /* Ensure the loader is above everything else */
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  border: 8px solid #f3f3f3;
  border-top: 8px solid #00ba8c;
  border-radius: 50%;
  width: 75px;
  height: 75px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}


@media screen and (max-width: 978px) {
  .lkjhghj {
    width: 200px;
  }
}

@media screen and (max-width: 690px) {
  .lkjhghj {
    width: 160px;
  }
}

@media screen and (max-width: 554px) {
  .lkjhghj {
    width: 160px;
  }

  .raiseTicketContainer {
    display: grid;
    justify-content: center;
  }

  .lkjhghj {
    display: none;
  }

  .formSection {
    width: auto;
  }

  .contactSection {
    width: 45%;
    text-align: left;
    margin-left: 20px;
  }
}