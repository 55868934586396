.medical-library {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  gap: 2.5rem;
  flex-direction: column;
  background-color: var(--medical-library-background);
}

.medical-top {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.library-input-container {
  width: 35%;
  display: flex;
  justify-content: start;
  gap: 0.5rem;
  align-items: center;
  padding: 0px 15px;
  border-radius: 12px;
  background-color: var(--library-input-background);
  outline: none;
  border: 0px;
}

.lib-input {
  padding: 15px;
  font-size: 25px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  line-height: 24.38px;
}

.logo-or-img {
  width: 13%;
  overflow: hidden;
  margin-bottom: -20px;
}

.logo-or-img>img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.lkjlk {
  font-size: 44px;
  font-weight: 700;
  letter-spacing: 0.5px;
  margin-bottom: 12px;
}

.query-input-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-input-query {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-right: -5%;
}


.medical-top>h1 {
  font-size: 3rem;
}

.font-icon {
  font-size: 2rem;
}

.verified-icon {
  font-size: 2rem;
}

.send-query-btn {
  border-radius: 10px;
  outline: none;
  border: 0px;
  background-color: var(--send-query-button-background);
  padding: 15px;
  font-size: 18px;
  display: flex;
  gap: 0.25rem;
  justify-content: center;
  align-items: center;
  color: var(--light-text-color);
  font-weight: 500;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  transition: transform 0.5s ease-in-out;
}

.send-query-btn:hover {
  transform: scale(1.08);
}

.filter {
  background-color: transparent;
  border: 2px solid var(--filter-dropdown-border);
  color: var(--light-text-color);
  font-weight: 600;
}

.filtering {
  font-weight: 600;
  background-color: var(--filter-dropdown-border);
}

.cards-container {
  width: 80%;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 3rem;
  justify-content: center;
  align-items: center;
  position: relative;
}

.new-badge {
  position: absolute;
  border-radius: 15px;
  left: 2%;
  padding: 8px 10px;
  top: -12%;
  min-width: 20%;
  font-weight: 600;
  font-size: 30px;
  background-color: var(--new-badge-background);
}

.medi-card {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 22%;
  border-width: 3px;
  padding: 3rem 2rem;
  border-radius: 30px;
}

.card-content {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: start;
  justify-content: center;
}

.card-content>h3 {
  color: var(--card-content-text-color);
  font-size: 30px;
  padding: 1rem 0rem;
  font-weight: 600;
}

.card-content>h6 {
  font-size: 26px;
  font-weight: 600;
}

.padding {
  padding: 10px;
}

.lib-icon-container {
  position: absolute;
  right: 3%;
  top: 4%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  padding: 5px 5px 0px 0px;
}

.flag-icon {
  font-size: 2rem;
}

.verified-icon-medic {
  font-size: 3rem;
}

.main-query-container {
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.medi-loader {
  width: 2rem;
  height: 2rem;
  border: 5px solid #0c0b0b;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  position: absolute;
  right: 2%;
  top: 2%;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 1800px) {
  .medical-library {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 2.5rem;
    flex-direction: column;
  }

  .medical-top {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
  }

  .medical-top>h1 {
    font-size: 2rem;
  }

  .font-icon {
    font-size: 1.5rem;
  }

  .verified-icon {
    font-size: 1.25rem;
  }

  .library-input-container {
    width: 40%;
    display: flex;
    justify-content: start;
    gap: 0.5rem;
    align-items: center;
    padding: 0px 15px;
    border-radius: 6px;
    background-color: var(--library-input-background);
    outline: none;
    border: 0px;
  }

  .lib-input {
    padding: 10px 10px;
    font-size: 15px;
    font-family: "Montserrat", sans-serif;
    font-weight: 500;
    line-height: 24.38px;
  }

  .logo-or-img {
    width: 10rem;
    height: 7.5rem;
    overflow: hidden;
  }

  .logo-or-img>img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  .query-input-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .flag-icon {
    font-size: 1.5rem;
  }

  .verified-icon-medic {
    font-size: 2.0rem;
  }

  .main-input-query {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-right: -5%;
  }

  .send-query-btn {
    border-radius: 10px;
    outline: none;
    border: 0px;
    background-color: var(--send-query-button-background);
    padding: 13px;
    font-size: 12px;
    display: flex;
    gap: 0.25rem;
    justify-content: center;
    align-items: center;
    color: var(--light-text-color);
    font-weight: 500;
    cursor: pointer;
    font-family: "Montserrat", sans-serif;
    transition: background-color 0.3s, color 0.3s;
  }

  .send-query-btn:hover {
    background-color: var(--send-query-button-hover-background);
  }

  .filter {
    background-color: transparent;
    border: 2px solid var(--filter-dropdown-border);
    color: var(--light-text-color);
    font-weight: 600;
  }

  .filtering {
    font-weight: 600;
    background-color: var(--filter-dropdown-border);
  }

  .cards-container {
    width: 80%;
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    gap: 3rem;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .new-badge {
    position: absolute;
    border-radius: 13px;
    left: 2%;
    padding: 6px 10px;
    top: -10%;
    min-width: 20%;
    font-weight: 600;
    font-size: 18px;
    background-color: var(--new-badge-background);
  }

  .medi-card {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 22%;
    padding: 2.5rem 1.5rem;
    border-radius: 30px;
  }

  .card-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: start;
    justify-content: center;
  }

  .card-content>h3 {
    color: var(--card-content-text-color);
    font-size: 20px;
    font-weight: 600;
    padding: 0.5rem 0rem;
  }

  .card-content>h6 {
    font-size: 16px;
    font-weight: 600;
  }

  .lib-icon-container {
    position: absolute;
    right: 2%;
    top: 2%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.5rem;
    padding: 5px 5px 0px 0px;
  }

  .main-query-container {
    width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .medi-loader {
    width: 2rem;
    height: 2rem;
    border: 5px solid #0c0b0b;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
    position: absolute;
    right: 2%;
    top: 2%;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}



@media screen and (max-width: 463px) {
  .medi-card {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 237px;
    padding: 2.5rem 1.5rem;
    border-radius: 30px;
  }

  .cards-container {
    width: 80%;
    padding: 20px;
    display: grid;
    flex-wrap: wrap;
    gap: 3rem;
    justify-content: center;
    align-items: center;
    position: relative;
  }
}