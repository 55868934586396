.videoLessonContainer {
  padding: 20px;
}

.backButton {
  background: transparent;
  border: 0;
  cursor: pointer;
}

/* styles/labLesson.css */

.headerContainer {
  position: relative;
}

.backButton {
  position: relative;
}

.backButton .tooltip {
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s;
}

.backButton:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.lessonName {
  flex-grow: 1;
  text-align: center;
  font-size: 24px;
  margin: 0;
}

.downloadPDF {
  display: flex;
  align-items: center;
}

.downloadPDF input {
  margin-right: 5px;
}

.videoContent {
  padding: 30px;
}

.lkjkk {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 33px;
}

.iuy {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-left: 67px;
  margin-top: 24px;
}

.doneButton {
  background-color: var(--done-button-bg-color);
  color: var(--light-text-color);
  border: none;
  border-radius: 91px;
  padding: 30px 17px;
  cursor: pointer;

  font-size: 18px;
  transition: background-color 0.3s, color 0.3s, transform 0.5s ease-in-out;
}

.doneButton:hover {
  background-color: var(--done-button-hover-bg-color);
  transform: scale(1.08);
}

.oiuyiit {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 33px;
}

.ourg {
  max-width: 1120px;
  width: 100%;
  border: 10px solid #00ba8c;
}

@media screen and (max-width: 1120px) {
  .ourg {
    width: 100%;
  }
}

@media screen and (max-width: 545px) {
  .ourg {
    width: 100%;
  }

  .doneButton {
    background-color: var(--done-button-bg-color);
    color: var(--light-text-color);
    border: none;
    border-radius: 91px;
    padding: 23px 12px;
    cursor: pointer;
    position: fixed;
    bottom: 20px;
    right: 20px;
    font-size: 18px;
  }
}

.videoContent {
  padding: 30px;
  display: grid;
  justify-content: center;
}
