/* Parent container needs to take full viewport height */
.password-change-page {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Full viewport height */
  margin: 0; /* Remove default margin */
}

/* Form styling */
.password-change-form {
  width: 100%;
  max-width: 950px;
  padding: 50px 100px;
  border-radius: 24px;
  background-color: var(--card-background-color);
  display: grid;
  justify-content: center;
  box-shadow: 0 0 9px var(--border-color);
}

.error-border {
  border: 1px solid red !important; /* Adjust thickness as necessary */
}

.password-change-title {
  text-align: center;
  font-size: 33px;
  font-weight: 500;
  margin-bottom: 35px;
}

.password-change-label {
  font-size: 16px;
  font-weight: 500;
  color: var(--text-color);
}

.password-change-input {
  width: 100%;
  height: 46px;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid var(--form-border-color);
  border-radius: 10px;
  outline: none;
  font-family: "Montserrat", sans-serif;
  background-color: var(--background-color);
  color: var(--form-text-color);
}

.password-msg {
  width: 400px;
}

.password-change-submit-button {
  width: 100%;
  display: flex;
  justify-content: center;
}

.password-change-submit {
  width: 174px;
  height: 48px;
  padding: 10px;
  margin: 10px 0;
  border: none;
  border-radius: 60px;
  background-color: var(--submit-bg-color);
  color: var(--light-text-color);
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s ;
  font-family: "Montserrat", sans-serif;
  transition: transform 0.5s ease-in-out;
}

.password-change-submit:hover {
  background-color: var(--submit-hover-bg-color);
  transform: scale(1.1)
}

.password-change-submit:active {
  transform: scale(0.9);
}

@media screen and (max-width: 768px) {
  .password-change-page {
    padding-top: 100px;
    margin: 0 10px;
  }

  .password-change-form {
    padding: 30px 20px;
    z-index: -1;
  }

  .password-change-title {
    font-size: 28px;
  }

  .password-change-input {
    height: 40px;
  }
}

@media screen and (max-width: 584px) {
  .password-change-page {
    padding-top: 50px;
    margin: 0 10px;
  }

  .password-change-form {
    padding: 20px 15px;
    margin-top: 100px;
  }

  .password-change-title {
    font-size: 24px;
  }

  .password-change-input {
    width: 100%;
    height: 36px;
    padding: 8px;
  }

  .password-field {
    width: 100%;
  }

  .password-msg {
    width: 100%;
  }

  .password-change-submit {
    width: 150px;
    height: 40px;
  }
}

.password-input-error {
  border: 1px solid red;
}

.password-field {
  position: relative;
  margin-bottom: 20px;
}

.input-group {
  position: relative;
}

.password-change-input {
  width: 100%;
  padding-right: 40px;
}

.icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-43%);
  cursor: pointer;
  color: #ccc;
}

.icon:hover {
  color: #888;
}

.password-validation-message {
  color: red;
  font-size: 14px;
  margin-top: 5px;
}
