.popupOverlay1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.emojiImg {
  width: 58px;
}

/* For WebKit browsers */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  input[type="range"] {
    overflow: hidden;
    width: 410px;
    -webkit-appearance: none;
    background-color: #ADADAD;
    cursor: pointer;
    /* Ensure the cursor is a pointer when hovering over the slider */
  }

  input[type="range"]::-webkit-slider-runnable-track {
    height: 10px;
    -webkit-appearance: none;
    color: #00ba8c;
    margin-top: -1px;
    border-radius: 5px;
    /* Make the track rounded */
  }

  input[type="range"]::-webkit-slider-thumb {
    width: 14px;
    /* Increase thumb size */
    height: 14px;
    /* Increase thumb size */
    -webkit-appearance: none;
    /* Make the thumb round */
    cursor: pointer;
    /* Change cursor to pointer */
    background: #484747;
    /* Thumb color */
    box-shadow: -410px 0 0 410px #00ba8c;
    /* Track color */
    transition: background-color 0.2s, box-shadow 0.2s;
    /* Smooth transition */
  }

  input[type="range"]::-ms-fill-lower {
    background-color: green;
    /* Track color up to the thumb */
    height: 6px;
    /* Decrease the height of the track */
    border-radius: 5px;
    /* Make the track rounded */
  }

  input[type="range"]::-ms-fill-upper {
    background-color: grey;
    /* Track color after the thumb */
    height: 6px;
    /* Decrease the height of the track */
    border-radius: 5px;
    /* Make the track rounded */
  }

}

/* For Firefox */
input[type="range"]::-moz-range-thumb {
  width: 24px;
  /* Increase thumb size */
  height: 24px;
  /* Increase thumb size */
  border-radius: 50%;
  /* Make the thumb round */
  cursor: pointer;
  /* Change cursor to pointer */
  background: #00ba8c;
  /* Thumb color */
  transition: background-color 0.2s;
  /* Smooth transition */
}

input[type="range"]::-moz-range-progress {
  background-color: #00ba8c;
  /* Track color up to the thumb */
}

input[type="range"]::-moz-range-track {
  background-color: grey;
  /* Track color after the thumb */
  border-radius: 5px;
  /* Make the track rounded */
}

input[type="range"]::-moz-range-thumb:hover {
  background: #00a87b;
  /* Darker thumb color on hover */
}

/* For IE */
input[type="range"]::-ms-thumb {
  width: 24px;
  /* Increase thumb size */
  height: 24px;
  /* Increase thumb size */
  border-radius: 50%;
  /* Make the thumb round */
  cursor: pointer;
  /* Change cursor to pointer */
  background: #00ba8c;
  /* Thumb color */
  transition: background-color 0.2s;
  /* Smooth transition */
}

input[type="range"]::-ms-fill-lower {
  background-color: #00ba8c;
  /* Track color up to the thumb */
  border-radius: 5px;
  /* Make the track rounded */
}

input[type="range"]::-ms-fill-upper {
  background-color: grey;
  /* Track color after the thumb */
  border-radius: 5px;
  /* Make the track rounded */
}


/* General input styling */
input[type="range"] {
  height: 4px;
  border-radius: 2px;
  /* Make the track rounded */
  outline: none;
}


.popupContent1 {
  background: var(--popup-bg-color);
  padding: 40px;
  border-radius: 10px;
  width: 50%;
  width: 1110px;
  height: 709px;
  position: relative;
  color: var(--popup-text-color);
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: var(--popup-text-color);
}

.popupTitle {
  margin-bottom: 20px;
  font-size: 30px;
  font-weight: 700;
  color: var(--popup-text-color);
}

.popupBody1 {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}

.wellBeingContainer,
.stressLevelContainer,
.noteContainer {
  margin-bottom: 20px;
}

.emojiSliderContainer {
  margin-bottom: 40px;
}

.wellBeingSlider,
.stressLevelSlider,
.emojiSlider {
  width: 420px;
  margin-top: 40px;
  cursor: pointer;
  background-color: var(--popup-slider-color);
  height: 4px;
}

.moodEmoji {
  display: block;
  margin: 10px 0;
}

.noteTextarea {
  width: 452px;
  height: 380px;
  resize: none;
  padding: 10px;
  border-radius: 14px;
  border: 1px solid var(--popup-border-color);
  margin-top: 35px;
  outline: none;
  font-family: "Montserrat", sans-serif;
  background-color: var(--background-color);
  color: var(--popup-text-color);
}

.saveButton {
  background: var(--submit-bg-color);
  color: var(--light-text-color);
  border: none;
  padding: 13px 80px;
  border-radius: 70px;
  cursor: pointer;
  font-size: 17px;
  margin-top: 20px;
  transition: background-color 0.3s, color 0.3s, transform 0.5s ease-in-out;
  position: relative;
  font-family: "Montserrat", sans-serif;
  /* Added for tooltip */
}

.saveButton:hover {
  transform: scale(1.08);
}

.ghjkl {
  display: grid;
  grid-gap: 48px;
}

.headij {
  font-size: 20px;
  font-weight: 600;
  color: var(--popup-text-color);
}

.writenote {
  font-size: 20px;
  font-weight: 600;
  color: var(--popup-text-color);
}

.oiuyt {
  display: flex;
  justify-content: center;
  width: 100%;
  font-family: "Montserrat", sans-serif;
}

.sliderValue {
  margin-left: 12px;
}

.emojiSlider {
  width: 100%;
  background-color: var(--popup-slider-color);
}

.emojiDisplay {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.emoji {
  font-size: 2rem;
}

.sliderValue {
  display: block;
  text-align: center;
  margin-top: 32px;
}

.pojuu {
  display: flex;
}

@keyframes closeAnimation {
  0% {
    opacity: 1;
    transform: scale(1);
  }

  100% {
    opacity: 0;
    transform: scale(0.95);
  }
}

.popupClosing {
  animation: closeAnimation 0.3s ease forwards;
}

.close-icon-container {
  position: relative;
  cursor: pointer;
  display: inline-block;
  margin-top: -29px;
}

.close-tooltip {
  visibility: hidden;
  background-color: black;
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px 10px;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -30px;
  /* Use half of the width to align it */
  opacity: 0;
  transition: opacity 0.3s, visibility 0.3s;
}

.close-icon-container:hover .close-tooltip {
  visibility: visible;
  opacity: 1;
}

.saveButton.disabled {
  background: var(--disabled-bg-color, grey);
  /* Define --disabled-bg-color in your CSS variables */
  cursor: not-allowed;
  transition: transform 0.5s ease-in-out;
}

.saveButton.disabled:hover::after {
  content: "You can only submit one journal per day.";
  visibility: visible;
  opacity: 1;
  font-size: 13px;
  transform: scale(1.08);
  font-family: "Montserrat", sans-serif;
}

.saveButton:hover::after {
  visibility: visible;
  opacity: 1;
  font-size: 13px;
  font-family: "Montserrat", sans-serif;
}

.saveButton::after {
  visibility: hidden;
  font-family: "Montserrat", sans-serif;
  width: 220px;
  background-color: black;
  color: white;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -110px;
  /* Adjusted for the wider tooltip */
  opacity: 0;
  transition: opacity 0.3s, visibility 0.3s;
}

@media screen and (max-width:1150px) {
  .popupBody1 {
    display: grid;
    justify-content: center;
    margin-top: 40px;
  }

  .jjjo {
    display: none;
  }

  .ghjkl {
    display: grid;
    grid-gap: 13px;
  }

  .emojiImg {
    width: 33px;
  }

  .wellBeingContainer,
  .stressLevelContainer,
  .noteContainer {
    margin-bottom: 0px;
  }

  .emojiSliderContainer {
    margin-bottom: 24px;
  }

  .noteTextarea {
    width: 452px;
    height: 118px;
    resize: none;
    padding: 10px;
    border-radius: 14px;
    border: 1px solid var(--popup-border-color);
    margin-top: 35px;
    outline: none;
    font-family: "Montserrat", sans-serif;
    background-color: var(--background-color);
    color: var(--popup-text-color);
  }

  .popupContent1 {
    background: var(--popup-bg-color);
    padding: 40px;
    border-radius: 10px;
    width: 518px;
    height: 709px;
    position: relative;
    color: var(--popup-text-color);
  }
}


@media screen and (max-width:554px) {
  .popupBody1 {
    display: grid;
    justify-content: center;
    margin-top: 40px;
  }

  .jjjo {
    display: none;
  }

  .ghjkl {
    display: grid;
    grid-gap: 13px;
  }

  .emojiImg {
    width: 33px;
  }

  .wellBeingContainer,
  .stressLevelContainer,
  .noteContainer {
    margin-bottom: 0px;
  }

  .emojiSliderContainer {
    margin-bottom: 24px;
  }

  .noteTextarea {
    width: 217px;
    resize: none;
    padding: 10px;
    border-radius: 14px;
    border: 1px solid var(--popup-border-color);
    margin-top: 35px;
    outline: none;
    font-family: "Montserrat", sans-serif;
    background-color: var(--background-color);
    color: var(--popup-text-color);
  }

  .popupContent1 {
    background: var(--popup-bg-color);
    padding: 40px;
    border-radius: 10px;
    width: 330px;
    height: 709px;
    position: relative;
    color: var(--popup-text-color);
  }

  .popupTitle {
    margin-bottom: 20px;
    font-size: 19px;
    font-weight: 700;
    color: var(--popup-text-color);
  }

  input[type="range"] {
    overflow: hidden;
    width: 200px;
    -webkit-appearance: none;
    background-color: #ADADAD;
    cursor: pointer;
    /* Ensure the cursor is a pointer when hovering over the slider */
  }

  input[type="range"]::-webkit-slider-thumb {
    width: 14px;
    /* Increase thumb size */
    height: 14px;
    /* Increase thumb size */
    -webkit-appearance: none;
    /* Make the thumb round */
    cursor: pointer;
    /* Change cursor to pointer */
    background: #484747;
    /* Thumb color */
    box-shadow: -200px 0 0 200px #00ba8c;
    /* Track color */
    transition: background-color 0.2s, box-shadow 0.2s;
    /* Smooth transition */
  }

  .pojuu {
    display: flex;
    width: 197px;
  }
}