.heahder {
  display: flex;
  justify-content: start;
  margin-top: 30px;
}

.button-animate {
  transition: transform 0.3s ease-in-out;
}

.button-animate:active {
  transform: scale(0.9);
}

.poiyt {
  padding: 20px 50px;
}

.backButton {
  background: transparent;
  border: 0;
  cursor: pointer;
}

/* styles/labLesson.css */

.headerContainer {
  position: relative;
}

.backButton {
  position: relative;
}

.backButton .tooltip {
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s;
}

.backButton:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.title {
  text-align: center;
  font-size: 36px;
  text-align: left;
}

.groww {
  flex-grow: 1;
}

.btn-container {
  display: flex;
  gap: 1.5rem;
  justify-content: space-evenly;
  align-items: center;
  position: relative;
}

.crop-container {
  position: relative;
  width: 100%;
  height: 30vh;
  background: #333;
}

.controls {
  width: 50%;
  position: absolute;
  bottom: 4%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.requestButton {
  background-color: var(--submit-bg-color);
  color: var(--light-text-color);
  border: none;
  padding: 8px 10px;
  border-radius: 5px;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  display: flex;
  gap: 0.25rem;
  align-items: center;
  transition: transform 0.5s ease;
}

.requestButton:hover {
  transform: scale(1.08);
}

.filterContainer {
  z-index: 10;
}

.filterButton {
  background: none;
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  display: flex;
  gap: 0.25rem;
  align-items: center;
  border: 1px solid var(--filter-dropdown-border);
  padding: 8px 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.5s ease;
  color: var(--text-color);
}

.filterButton:hover {
  transform: scale(1.08);
}

.filterDropdown {
  position: absolute;
  top: 42px;
  background: var(--filter-dropdown-background);
  border: 1px solid var(--filter-dropdown-border);
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.filterOption {
  padding: 10px 20px;
  cursor: pointer;
  display: flex;
  gap: 0.5rem;
}

.filterOption:hover {
  background: var(--medium-text-color);
}

.search-bar-association {
  padding: 5px 10px;
  color: var(--search-bar-text-color);
  border: 1px solid var(--search-bar-border);
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  border-radius: 5px;
  width: 25vw;
  background-color: var(--search-bar-background);
  display: flex;
  justify-content: start;
  gap: 0.5rem;
}

.searchInput {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  width: 95%;
  background: transparent;
  outline: none;
  border: 0px;
}

.cardsGrid {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 4rem;
  margin-top: 50px;
  flex-wrap: wrap;
}

.card1 {
  cursor: pointer;
  background: var(--card-background-color);
  border: 1px solid var(--card-border-color);
  border-radius: 10px;
  padding: 10px;
  position: relative;
  width: 12%;
  text-align: center;
  transition: transform 0.5s ease;
}

.card1:hover {
  transform: scale(1.08);
}

.flex-container-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
}

.associationbox {
  aspect-ratio: 1 / 1;
  /* Make the element square */
  text-align: center;
  box-sizing: border-box;
  /* Ensure padding and border are included in the height and width */
}

.cardNewBadge {
  position: absolute;
  top: 10px;
  left: 0px;
  background-color: var(--card-new-badge-background);
  color: var(--light-text-color);
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
}

.cardImage {
  background: var(--card-image-background);
  width: 100%;
  aspect-ratio: 1 / 1;
  margin-bottom: 10px;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.cardImage>img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.cardReplies {
  font-size: 14px;
  margin-bottom: 5px;
}

.cardReplyIcon {
  font-size: 20px;
  color: var(--card-reply-icon-color);
}

.commentIcon {
  transition: transform 0.5s ease;
}

.commentIcon:hover {
  transform: scale(1.08);
}

@media screen and (max-width: 1800px) {
  .btn-container {
    gap: 0.5rem;
  }

  .requestButton {
    padding: 5px 10px;
  }

  .filterButton {
    padding: 5px 10px;
  }

  .heahder {
    margin-top: 20px;
  }
}

@media screen and (max-width: 1270px) {
  .card1 {
    width: 15%;
  }
}

@media screen and (max-width: 900px) {
  .card1 {
    width: 20%;
  }

  .cardsGrid {
    gap: 2rem;
  }
}

@media screen and (max-width: 650px) {
  .card1 {
    width: 25%;
  }
}

@media screen and (max-width: 600px) {
  .cardsGrid {
    justify-content: center;
  }

  .card1 {
    width: 30%;
  }
}

@media screen and (max-width: 500px) {
  .card1 {
    width: 40%;
  }

  .cardsGrid {
    gap: 2rem;
  }
}


.request {
  width: 20px;
}

@media screen and (max-width: 872px) {
  .heahder {
    display: grid;
  }

  .btn-container {
    margin-top: 20px;
  }
}

@media screen and (max-width: 457px) {
  .heahder {
    display: grid;
  }

  .btn-container {
    margin-top: 20px;
  }

  .title {
    font-size: 24px;
  }

  .poiyt {
    padding: 20px 15px;
  }

  .cardReplies {
    font-size: 9px;
    margin-bottom: 5px;
  }

  .request {
    width: 15px;
  }
}