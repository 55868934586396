.statisticsList {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* Fixed four columns on larger screens */
  grid-gap: 40px; /* Consistent spacing */
  padding: 20px 60px;
  margin-top: 50px;
  margin-bottom: 50px; /* Additional space below the grid */
}

@media (max-width: 768px) {
  .statisticsList {
    grid-template-columns: 1fr; /* Single column on mobile screens */
    padding: 20px;
  }
}

.dark-mode-img {
  filter: brightness(0) invert(1); /* Inverts the colors making white become black */
}

.center-img{
  display: flex;
  justify-content: center;
  align-items: center;
}

.responsive-img {
  max-width: 100%;
  max-height: 220px;    
}

.select-container {
  margin: 20px 0;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.numberf {
  width: 120px;
  background-color: #fff;
  padding: 6px 0px;
  border-radius: 10px;
  border: 1px solid #D8D9DA;
  display: flex;
  align-items: center;
  justify-content: center; 
  cursor: pointer;
  transition: transform 0.4s ease-in-out;
}

.numberf:hover {
  transform: scale(1.08);
}

.numberf:active {
  transform: scale(0.9);
}

.kuu {
  font-size: 15px;
  font-weight: 500;
  color: #000;
  margin: 5px 2px;
  letter-spacing: 0.2px;
  font-family: "Montserrat", sans-serif;
}


.select-container select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 10px 15px;
  font-size: 1rem;
  border-radius: 5px;
  border: 1px solid var(--select-border-color);
  width: 100%;
  max-width: 400px;
  box-sizing: border-box;
  background: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" viewBox="0 0 24 24"><path fill="none" stroke="%23333" stroke-width="2" d="M8 9l4 4 4-4z"/>') no-repeat right 10px center;
  background-color: var(--select-background-color);
  color: var(--select-text-color);
  background-size: 10px;
}

.select-container select:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
  outline: none;
}

@media (max-width: 600px) {
  .select-container {
    padding: 5px;
  }

  .select-container select {
    font-size: 0.9rem;
    padding: 8px 12px;
  }
}

.center-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 150px;
}

.staisticsCard {
  width: 100%;
  border-radius: 15px;
  border-top: 1px solid var(--statistics-card-border-color);
  border-left: 1px solid var(--statistics-card-border-color);
  border-right: 1px solid var(--statistics-card-border-color);
  background-color: var(--statistics-card-background-color);
  color: var(--statistics-card-text-color);
}

.fgj {
  padding: 20px;
  padding-bottom: 0;
}

.wo {
  font-size: 16px;
  font-weight: 400;
  height: 38px;
}

.hr {
  font-size: 40px;
  font-weight: 700;
}

.wave {
  display:flex;
  width: 100%;
  height: auto;
  margin: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

.chartContainer {
  margin: 30px;
  padding: 20px;
  margin-bottom: 10px;
  height: 300px;
  /* background-color: var(--statistics-card-background-color); */
  color: var(--statistics-card-text-color);
}

.chart-and-filter-container {
  margin-top: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.filter-container {
  position: absolute;
  cursor: pointer;
  top: 10px;
  right: 33px;
  display: flex;
  align-items: center;

}

.filter-inner {
  display: flex;
  align-items: center;
  border: 1px solid rgb(229, 226, 226);
  padding: 8px;
  border-radius: 5px;
}

.calendar-icon {
  color: #00b8ac;
  margin-right: 10px;
}

#status-select {
  cursor: pointer;
  border: none;
  outline: none;
  font-family: Montserrat, sans-serif;
  font-size: 16px;
}

.chart {
  max-width: max-content;
}

@media (max-width: 600px) {
  .chartContainer {
    height: 300px;
  }
}

@media (min-width: 1201px) {
  .chartContainer {
    height: 400px;
  }
}

.userListContainer {
  margin: 30px;
  overflow-x: auto;
}

.userList {
  width: 100%;
  border-collapse: collapse;
}

.userList th,
.userList td {
  padding: 12px;
  text-align: left;
}

.userList th {
  background-color: var(--userlist-header-bg-color);
  font-weight: bold;
}

.userList tr:nth-child(even) {
  background-color: var(--userlist-row-bg-color);
}

.userList tr:hover {
  box-shadow: 0 0 5px var(--userlist-hover-shadow-color);
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 250ms ease-out;
}

@media (max-width: 768px) {
  .userList th,
  .userList td {
    padding: 8px;
    font-size: 14px;
  }

  .userList {
    font-size: 12px;
  }
}

@media (max-width: 600px) {
  .userList th,
  .userList td {
    padding: 6px;
    font-size: 12px;
  }

  .userList {
    font-size: 10px;
  }

  .userListContainer {
    margin: 10px;
    overflow-x: auto;
  }

  .pagination-button {
    padding: 5px 10px;
    font-size: 12px;
  }

  .pagination-info {
    font-size: 12px;
  }
}

.pagination {
  display: flex;
  align-items: center;
  margin: 20px 0;
}

.pagination-button {
  background-color: var(--pagination-button-bg-color);
  color: var(--pagination-button-text-color);
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.pagination-info {
  font-size: 16px;
}

.chart {
  width: 100%;
  margin-top: 20px;
  max-width: 1000px;
  max-height: 1000px;
  margin: 0 auto;
}

.chart-inner {
  margin-top: 100px;
  border-radius: 50px;
}

@media (max-width: 1200px) {
  .chart-inner {
    margin-top: 90px;
  }
}

@media (max-width: 992px) {
  .chart-inner {
    margin-top: 80px;
  }
}

@media (max-width: 768px) {
  .chart-inner {
    margin-top: 70px;
  }
}

@media (max-width: 600px) {
  .chart {
    height: auto; /* Adjust height to be auto for responsiveness */
  }

  .chart-inner {
    margin-top: 130px;
  }
}

@media (max-width: 480px) {
  .chart-inner {
    margin-top: 120px;
  }
}

@media (max-width: 360px) {
  .chart-inner {
    margin-top: 110px;
  }
}
