.tooltip-container{
    width: 40%;
    border-radius: 12px 0px 0px 0px;
    border: 1px solid #ccc;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: start;
    position: absolute;
    right: 0;
    bottom: 0;
}

.tooltip-header{
    width: 100%;
    padding: 1rem;
    font-size: 15px;
    display: flex;
    justify-content: end;
    border-bottom: 1px solid #ccc;
}

.tooltip-main{
    padding: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

.tooltip-description{
    width: 90%;
    border-radius: 10px;
    border: 1px solid #ccc;
    padding: 1rem;
}

.close-icon-tooltip{
    transition: transform 0.5s ease-in-out;
}
.close-icon-tooltip:hover{
    transform: scale(1.08);
}