.library-view {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  background-color: white;
  width: 23%;
}
.library {
  padding: 15px;
  justify-content: end;
}

.library-main-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 14px;
}
.lib-date {
  font-weight: 600;
  font-size: 13px;
}

.verification {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
  font-weight: 600;
  font-size: 12px;
}
.temp-heading {
  font-size: 1.75rem;
  color: black;
  font-weight: bold;
}

.temp-a {
  text-decoration: none;
  padding: 1rem;
}
.in-lib-view {
  margin-bottom: 1.5vh;
}
@media screen and (max-width: 1800px) {
  .library-main-content {
    padding: 14px;
  }
  .temp-heading {
    font-size: 1.25rem;
  }
  .temp-a {
    padding: 10px;
  }
}
