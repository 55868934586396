/* Center the popup in the middle of the screen with a dark background overlay */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

/* Style the popup content */
.popup-inner {
  background: var(--popup-bg-color);
  padding: 30px;
  border-radius: 10px;
  width: 90%;
  max-width: 500px;
  text-align: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  position: relative;
}

/* Add styles for the popup title */
.popup-inner h2 {
  margin-bottom: 20px;
  font-size: 24px;
  color: var(--popup-text-color);
}

/* Style the labels and form elements */
.labhjj {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
  color: var(--popup-text-color);
  text-align: left;
}

.oiuyuu[type="text"],
.oiuyuu {
  width: 100%;
  padding: 10px;
  margin: 5px 0 20px;
  box-sizing: border-box;
  border: 1px solid var(--form-border-color);
  border-radius: 5px;
  font-size: 16px;
  background: var(--background-color);
  color: var(--form-text-color);
}

textarea {
  resize: vertical;
  height: 100px;
}

/* Style the file input */
input[type="file"] {
  margin: 10px 0 20px;
}

/* Style the preview image */
.image-preview {
  width: 100px;
  height: 100px;
  object-fit: cover;
  margin-top: 10px;
  border-radius: 5px;
  border: 1px solid #ddd;
  margin-bottom: 25px;
}

/* Style the buttons */
.bito {
  padding: 12px 39px;
  margin: 10px 5px;
  cursor: pointer;
  border: none;
  border-radius: 60px;
  font-size: 16px;
}

.bito[type="submit"] {
  background: var(--submit-bg-color);
  border-radius: 60px;
  color: #fff;
  transition: background 0.3s;
}

.bito[type="submit"]:hover {
  background: var(--submit-hover-bg-color);
}

.bito[type="button"] {
  border-radius: 60px;
  background: #6c757d;
  color: #fff;
  transition: background 0.3s;
}

.bito[type="button"]:hover {
  background: #5a6268;
}

.preview-img {
  width: 100px;
  margin-bottom: 22px;
}

.quote-field {
  display: flex;
  flex-direction: column;
  justify-content: start;
}

.quote-title {
  margin: 8px 0px 3px 0px;
  text-align: left;
  font-size: 13px;
}

.file-drop-container {
  min-height: 30vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.quote-input {
  padding: 15px;
  border-radius: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  outline: none;
  letter-spacing: 1px;
}

.quote-input:focus {
  border: 2px solid #b1b1b1;
}

.success-screen {
  background: #fff;
  padding: 30px 10px;
  padding-top: 10px;
  border-radius: 10px;
  width: 25%;
  text-align: center;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  position: relative;
}

.popup-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}
.popup-main-heading {
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  height: 30vh;
}

.popup-main-heading > h5 {
  color: #00ba8c;
  font-size: 1.25rem;
}
.popup-main-heading > h6 {
  font-size: 1rem;
  color: #05bb8e;
}
.popup-main-heading > p {
  font-size: 14px;
  font-weight: 700;
  text-align: left;
  color: black;
  font-family: "Montserrat", sans-serif;
}
.popup-main-heading > textarea {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
}

.main-popup-component {
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-left {
  width: 50%;
  padding: 5px 10px;
}

.cred-item {
  display: flex;
  gap: 0.75rem;
}

.subtest-name-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin: 2vh 0vh;
  gap: 1rem;
  flex-wrap: wrap;
}

.sub-btn {
  border: 1px solid black;
  font-weight: 600;
  padding: 8px;
  border-radius: 10px;
}
@media screen and (max-width: 650px) {
  .main-popup-component {
    flex-direction: column;
  }
  .popup-left {
    width: 90%;
  }
  .popup-inner {
    max-height: 85%;
  }
}
