.labLessonContainer {
  padding-top: 26px;
}

.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 100%;
  padding: 0px 80px;
  border-bottom: 1px solid var(--header-border-color);
  padding-bottom: 22px;
}

.backButton {
  background: transparent;
  border: 0;
  cursor: pointer;
}

/* styles/labLesson.css */

.headerContainer {
  position: relative;
}

.backButton {
  position: relative;
}

.backButton .tooltip {
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s;
}

.backButton:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

/* styles/labLesson.css */

.lessoncard.disabled {
  position: relative;
  cursor: not-allowed;
  opacity: 0.5;
}

.timeline .line {
  height: 120px;
  width: 3px;
  background-color: var(--dot-color);
  flex-grow: 1;
}

.timeline .line.completed {
  background-color: var(--dot-completed-color);
}

.courseName {
  flex-grow: 1;
  text-align: center;
  font-size: 31px;
  margin: 0;
}

.progressBar {
  font-size: 18px;
}

.lessonCards {
  display: flex;
  align-items: center;
  justify-content: center;
}

.lessoncard {
  transition: transform 0.3s ease-in-out;
}

.lessoncard:hover {
  transform: scale(1.08);
}

.button-animate {
  transition: transform 0.3s ease-in-out;
}

.button-animate:active {
  transform: scale(0.9);
}
.timeline {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 46px;
  margin-top: 90px;
}

.dot {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--dot-color);
  margin: 10px 0;
}

.dot.completed {
  background-color: var(--dot-completed-color);
}

.line {
  width: 2px;
  height: 120px;
  background-color: var(--dot-color);
}

.lessoncards {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 100px;
}

.lessoncard {
  display: flex;
  align-items: center;
  background-color: var(--lessoncard-bg-color);
  border: 1px solid var(--lessoncard-border-color);
  border-radius: 10px;
  padding: 0px 40px;
  width: 875px;
  height: 139px;
  cursor: pointer;
  transition: transform 0.5s ease-in-out, box-shadow 0.3s;
}

.lessoncard:hover {
  transform: scale(1.08);

  box-shadow: 0 0 5px var(--lessoncard-hover-shadow-color);
}

.lessoncard .lessonimg {
  width: 50px;
  height: 50px;
  margin-right: 20px;
}

.cardContent {
  display: flex;
  flex-direction: column;
}

.cardContent .lessionh1 {
  margin: 0;
  font-weight: 400;
}

.cardContent .lessonp {
  margin: 0;
  font-size: 25px;
  font-weight: 700;
  margin-top: 3px;
}

.editIcon1 {
  color: var(--edit-icon-color);
  cursor: pointer;
  position: absolute;
  right: 277px;
}

.deleteIcon1 {
  color: #f87171;
  cursor: pointer;
  position: absolute;
  right: 300px;
}

.addLessonButton {
  margin-right: 10px;
  padding: 11px 21px;
  border: none;
  background-color: var(--primary-color);
  color: var(--light-text-color);
  border-radius: 5px;
  cursor: pointer;
  position: absolute;
  right: 35px;
  top: 120px;
  font-size: 15px;
  transition: background-color 0.3s, color 0.3s;
}

.addLessonButton:hover {
  background-color: var(--primary-hover-color);
}

.lessonuu {
  font-size: 16px;
  font-weight: 500;
  color: var(--desc-text-color);
}

@media screen and (max-width: 972px) {
  .lessoncard {
    display: flex;
    align-items: center;
    background-color: var(--lessoncard-bg-color);
    border: 1px solid var(--lessoncard-border-color);
    border-radius: 10px;
    padding: 0px 40px;
    width: 440px;
    height: 139px;
    cursor: pointer;
  }
}

@media screen and (max-width: 679px) {
  .lessoncard {
    display: flex;
    align-items: center;
    background-color: var(--lessoncard-bg-color);
    border: 1px solid var(--lessoncard-border-color);
    border-radius: 10px;
    padding: 0px 40px;
    width: 277px;
    height: 139px;
    cursor: pointer;
  }

  .cardContent .lessonp {
    margin: 0;
    font-size: 19px;
    font-weight: 700;
    width: 118px;
    overflow-wrap: break-word;
  }

  .editIcon1 {
    color: var(--edit-icon-color);
    cursor: pointer;
    position: absolute;
    right: 173px;
  }

  .deleteIcon1 {
    color: #f87171;
    cursor: pointer;
    position: absolute;
    right: 50px;
  }

  .timeline {
    display: none;
  }
}

@media screen and (max-width: 505px) {
  .lessoncard {
    display: flex;
    align-items: center;
    background-color: var(--lessoncard-bg-color);
    border: 1px solid var(--lessoncard-border-color);
    border-radius: 10px;
    padding: 0px 40px;
    width: 277px;
    height: 139px;
    cursor: pointer;
  }

  .cardContent .lessonp {
    margin: 0;
    font-size: 19px;
    font-weight: 700;
    width: 118px;
    overflow-wrap: break-word;
  }

  .editIcon1 {
    color: var(--edit-icon-color);
    cursor: pointer;
    position: absolute;
    right: 123px;
  }

  .deleteIcon1 {
    color: #f87171;
    cursor: pointer;
    position: absolute;
    right: 50px;
  }

  .timeline {
    display: none;
  }

  .addLessonButton {
    margin-right: 10px;
    padding: 11px 21px;
    border: none;
    background-color: var(--primary-color);
    color: var(--light-text-color);
    border-radius: 5px;
    cursor: pointer;
    position: absolute;
    right: -2px;
    top: 144px;
    font-size: 12px;
  }
}

@media screen and (max-width: 395px) {
  .lessoncard {
    display: flex;
    align-items: center;
    background-color: var(--lessoncard-bg-color);
    border: 1px solid var(--lessoncard-border-color);
    border-radius: 10px;
    padding: 0px 40px;
    width: 277px;
    height: 139px;
    cursor: pointer;
  }

  .cardContent .lessonp {
    margin: 0;
    font-size: 19px;
    font-weight: 700;
    width: 118px;
    overflow-wrap: break-word;
  }

  .editIcon1 {
    color: var(--edit-icon-color);
    cursor: pointer;
    position: absolute;
    right: 76px;
  }

  .deleteIcon1 {
    color: #f87171;
    cursor: pointer;
    position: absolute;
    right: 50px;
  }

  .timeline {
    display: none;
  }

  .addLessonButton {
    margin-right: 10px;
    padding: 9px 11px;
    border: none;
    background-color: var(--primary-color);
    color: var(--light-text-color);
    border-radius: 5px;
    cursor: pointer;
    position: absolute;
    right: -2px;
    top: 146px;
    font-size: 12px;
  }

  .courseName {
    flex-grow: 1;
    text-align: center;
    font-size: 18px;
    margin: 0;
  }

  .headerContainer {
    display: flex;
    justify-content: space-between;
    padding: 0px 10px;
  }
}
