/* tooltip.css */
.tooltip {
  position: absolute;
  bottom: 150%;
  /* Adjust as needed */
  left: 50%;
  transform: translateX(-50%);
  background-color: black;
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 14px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease;
  white-space: nowrap;
  z-index: 10;
}

.button-with-tooltip {
  position: relative;
  display: inline-block;
}

.button-with-tooltip:hover .tooltip {
  opacity: 1;
  visibility: visible;
}

/* Add the rest of your dashboard.css styles below */

/* dashboard.css */
.dashboard {
  display: flex;
}

.greeting {
  font-size: 44px;
  font-weight: 700;
  color: var(--dashboard-text-color);
}

.dashContainer {
  margin-left: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 60px;
}

.dashMain {
  padding: 50px 30px;
  display: grid;
  justify-content: center;
}

.goodmorning {
  font-size: 40px;
  color: var(--dashboard-text-color);
  font-weight: 700;
}

.board {
  display: flex;
  margin-top: 36px;
}

.showboard1 {
  margin-left: 30px;
  cursor: pointer;
}

.boardicuy {
  width: 722px;
  height: 222px;
  border-radius: 16px;
  background-color: var(--secondary-color);
  border: 0;
  padding: 37px;
}

.fl {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.oiwuy {
  display: flex;
  margin-right: 30px;
}

.shape1 {
  position: absolute;
  top: 218px;
  left: 867px;
}

.shape2 {
  top: 274px;
  position: absolute;
  left: 960px;
}

.shape3 {
  position: absolute;
  left: 867px;
  top: 318px;
}

.shape4 {
  position: absolute;
  left: 961px;
  top: 392px;
}

.llkk {
  font-size: 24px;
  color: var(--light-text-color);
  font-weight: 700;
  margin-left: 18px;
}

.kjhg {
  font-size: 14px;
  color: var(--medium-text-color);
  font-weight: 400;
  margin-top: 4px;
}

.oiugii {
  font-size: 20px;
  color: var(--light-text-color);
  font-weight: 500;
  width: 640px;
  margin-top: 20px;
}

.jhghjh {
  width: 102px;
  height: 48px;
}

.kjhjh {
  width: 55px;
  height: 45.83px;
}

.frojj {
  height: 56px;
}

.editTextBujtton {
  padding: 15px 29px;
  background-color: var(--card-background-color);
  color: var(--card-text-color);
  border: none;
  border-radius: 60px;
  cursor: pointer;
  margin-right: 10px;
  font-size: 14px;
  font-family: Montserrat;
  font-weight: 500;
  transition: transform 0.5s ease-in-out;
}

.gfhfh {
  background-color: var(--card-background-color);
  color: var(--card-text-color);
  border: none;
  border-radius: 60px;
  cursor: pointer;
  font-size: 14px;
  padding: 15px 32px;
  margin-top: 26px;
  font-family: Montserrat;
  letter-spacing: 0.5px;
  font-weight: 500;
  transition: transform 0.5s ease-in-out;
  border: 2px solid #000;
}

.gfhfh:hover {
  transform: scale(1.08);
}

.jhhhhhj {
  letter-spacing: 0.6px;
}

.iuhyui {
  display: flex;
}

.howarey {
  background: linear-gradient(to right, #3586ff, #7ba0ff);
  width: 236px;
  height: 222px;
  border-radius: 16px;
  padding: 28px 22px;
  margin-left: 24px;
  font-family: Montserrat;
}

.poriuii {
  text-align: center;
  font-size: 16px;
  color: var(--light-text-color);
  font-weight: 500;
  margin-top: 23px;
}

.lhg {
  display: flex;
  justify-content: center;
  width: 100%;
}

.card-click-animation {
  transform: scale(0.97);
  transition: transform 0.1s ease;
}

.card-normal {
  transition: transform 0.1s ease;
}

.kjshshn {
  background-color: var(--card-background-color);
  color: var(--card-text-color);
  border: none;
  border-radius: 60px;
  cursor: pointer;
  font-size: 14px;
  padding: 13px 32px;
  margin-top: 26px;
  font-family: Montserrat;
  letter-spacing: 0.5px;
  font-weight: 500;
  transition: transform 0.5s ease-in-out;
}

.hhhb {
  position: absolute;
  left: 1194px;
  top: 218px;
}

.labCards1 {
  display: grid;
  grid-template-columns: auto auto auto auto;
  grid-gap: 80px 20px;
  margin-top: 97px;
}

.quote-loader {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-bottom: 30px;
}

.labcardd {
  border: 1px solid var(--medium-text-color);
  border-radius: 16px;
  margin-top: 51px;
  padding: 45px 30px;
}

.no-subtests {
  justify-content: center;
}

.studth {
  font-size: 33px;
  color: var(--dashboard-text-color);
  font-weight: 700;
}

.study-plan-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: start;
  align-items: start;
}

.study-plan {
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  padding: 1em;
  margin-top: 3vh;
  border: 2px solid var(--medium-text-color);
  min-width: 74%;
}

.dash-date {
  color: gray;
  font-weight: 500;
}

.dash-button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em;
  margin-top: 2vh;
}

.dash-button {
  border-radius: 999px;
  border: 1px solid var(--dashboard-text-color);
  background-color: transparent;
  padding: 1em 2em;
  outline: none;
  font-size: 15px;
  font-family: Montserrat;
  font-weight: 500;
}

.all-subtests {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 3vh 0vh;
}

.all-btn {
  border-radius: 38px !important;
  background-color: transparent;
  font-size: 15px !important;
}

@media screen and (max-width: 1250px) {
  .dashContainer {
    width: auto;
    margin-left: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}

@media screen and (max-width: 1032px) {
  .boardicuy {
    width: 522px;
    height: 222px;
    border-radius: 16px;
    background-color: var(--secondary-color);
    border: 0;
    padding: 37px;
  }

  .study-plan {
    width: 100%;
    flex-wrap: wrap;
  }

  .oiugii {
    font-size: 15px;
    color: var(--light-text-color);
    font-weight: 500;
    width: 439px;
    margin-top: 20px;
  }

  .header-title {
    font-size: 31px;
    font-weight: 700;
    color: var(--text-color);
  }

  .goodmorning {
    font-size: 34px;
    color: var(--dashboard-text-color);
    font-weight: 700;
  }
}

@media screen and (max-width: 820px) {
  .boardicuy {
    width: 349px;
    height: 207px;
    border-radius: 16px;
    background-color: var(--secondary-color);
    border: 0;
    padding: 19px;
  }

  .study-plan-container {
    justify-content: center;
  }

  .oiugii {
    font-size: 13px;
    color: var(--light-text-color);
    font-weight: 500;
    width: 312px;
    margin-top: 20px;
  }

  .header-title {
    font-size: 31px;
    font-weight: 700;
    color: var(--text-color);
  }

  .goodmorning {
    font-size: 34px;
    color: var(--dashboard-text-color);
    font-weight: 700;
  }

  .llkk {
    font-size: 14px;
    color: var(--light-text-color);
    font-weight: 700;
  }

  .kjhg {
    font-size: 12px;
    color: var(--medium-text-color);
    font-weight: 400;
    margin-top: 4px;
  }

  .editTextBujtton {
    padding: 7px 13px;
    background: var(--primary-color);
    color: var(--card-text-color);
    border: none;
    border-radius: 60px;
    cursor: pointer;
    margin-right: 10px;
    font-size: 13px;
  }

  .jhghjh {
    width: 59px;
    height: 33px;
  }

  .editTextBujtton {
    padding: 0px 16px;
    background: var(--primary-color);
    color: var(--card-text-color);
    border: none;
    border-radius: 60px;
    cursor: pointer;
    margin-right: 10px;
    font-size: 13px;
    height: 33px;
  }

  .howarey {
    height: 207px;
  }

  .labCards1 {
    display: flex;
    justify-content: center;
    grid-template-columns: auto auto;
    grid-gap: 80px 33px;
    margin-top: 82px;
  }

  .poriuii {
    text-align: center;
    font-size: 14px;
    color: var(--light-text-color);
    font-weight: 700;
    margin-top: 20px;
  }
}

@media screen and (max-width: 573px) {
  .board {
    display: grid;
    margin-top: 36px;
    justify-content: center;
  }

  .howarey {
    width: 349px;
    height: 207px;
    margin-left: 0px;
    margin-top: 15px;
  }

  .poriuii {
    text-align: center;
    font-size: 14px;
    color: var(--light-text-color);
    font-weight: 700;
    margin-top: 12px;
  }

  .labCards1 {
    display: grid;
    justify-content: center;
    grid-template-columns: auto;
    grid-gap: 112px 33px;
    margin-top: 117px;
  }

  .skoolcommunity {
    display: none;
  }

  .goodmorning {
    font-size: 25px;
    color: var(--dashboard-text-color);
    font-weight: 700;
  }

  .header-title {
    font-size: 18px;
    font-weight: 700;
    color: var(--text-color);
  }
}

@media screen and (max-width: 385px) {
  .boardicuy {
    width: 290px;
    height: 207px;
    border-radius: 16px;
    background-color: var(--secondary-color);
    border: 0;
    padding: 11px;
  }

  .oiugii {
    font-size: 13px;
    color: var(--light-text-color);
    font-weight: 500;
    width: 259px;
    margin-top: 20px;
  }

  .kjhjh {
    width: 37px;
    height: 32.83px;
  }

  .howarey {
    width: 290px;
    height: 207px;
  }

  .jhghjh {
    width: 49px;
    height: 33px;
  }
}

/* Common button animation */
.button-animate {
  transition: transform 0.3s ease-in-out;
}

.button-animate:active {
  transform: scale(0.9);
}

.submit:hover,
.editTextBujtton:hover,
.gfhfh:hover,
.kjshshn:hover {
  transform: scale(1.08);
}
