.backButton {
  background: transparent;
  border: 0;
  cursor: pointer;
}

/* styles/labLesson.css */

.headerContainer {
  position: relative;
}

.backButton {
  position: relative;
}

.backButton .tooltip {
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s;
}

.backButton:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.courseName {
  flex-grow: 1;
  text-align: center;
  font-size: 31px;
  margin: 0;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-top: 60px;
}

.clockIcon {
  width: 10%;
}

.cancelButton {
  background-color: #00ba8c;
  color: white;
  border: none;
  border-radius: 70px;
  padding: 16px 60px;
  cursor: pointer;
  font-family: "Montserrat", sans-serif;
  margin-top: 50px;
  transition: transform 0.5s ease-in-out;
}
.cancelButton:hover{
  transform: scale(1.08);
}

.tyui {
  margin-top: 30px;
  font-size: 52px;
  font-weight: 600;
}

.kjhg {
  display: flex;
  justify-content: center;
  width: 100%;
}

.tyukm {
  margin-top: 30px;
  font-size: 19px;
  font-weight: 500;
  width: 558px;
  color: #8a8e8f;
}

.animation-container {
  display: flex;
  align-items: baseline;
  width: 100%;
  justify-content: center;
}

.typing-animation {
  text-align: left;
  width: 1%;
}
.typing-animation::after {
  content: "";
  animation: typing 1.5s infinite;
}

@keyframes typing {
  0% {
    content: ".";
  }
  33% {
    content: "..";
  }
  66% {
    content: "...";
  }
}

@media screen and (max-width: 550px) {
  .clockIcon {
    width: 55%;
  }
}
