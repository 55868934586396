/* General Styles */
.loader-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 90vh; /* Make the loader container take up the full viewport height */
}

.action-icon {
  cursor: pointer;
  color: red;
  margin-left: 10px;
  transition: transform 0.3s ease;
}

.action-icon:hover {
  transform: scale(1.2);
}

.table-container {
  margin: 20px;
  padding: 10px;
  overflow-x: auto; /* Add horizontal scroll for small screens */
}

.reported-content-container {
  max-width: 1000px;
  margin: 0 auto;
}

.reported-section {
  margin-top: 20px;
}

.reported-section table {
  width: 100%;
  border-collapse: collapse;
}

.reported-section th,
.reported-section td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.reported-section tr:hover {
  background-color: #f5f5f5;
}

.even-row {
  background-color: #f9f9f9;
}

.odd-row {
  background-color: #ffffff;
}

/* Responsive Styles */
@media (max-width: 768px) {
  .reported-section table,
  .reported-section th,
  .reported-section td {
    display: block; /* Display each cell as a block element */
    width: 100%;
  }

  .reported-section th {
    display: none; /* Hide table headers */
  }

  .reported-section td {
    position: relative;
    padding-left: 50%; /* Add padding to make space for pseudo-elements */
    text-align: left;
  }

  .reported-section td::before {
    content: attr(
      data-label
    ); /* Add the column label using data-label attribute */
    position: absolute;
    left: 10px;
    width: calc(50% - 20px); /* Adjust width to fit the content */
    padding-right: 10px;
    white-space: nowrap;
    font-weight: bold;
  }

  .reported-content-container {
    max-width: 100%;
    padding: 10px;
  }

  .reported-section h3 {
    font-size: 1.2em;
  }

  .action-icon {
    margin-left: 0;
  }

  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 10px;
  }

  .pagination-button {
    margin: 0 5px;
  }

  .table-container {
    padding: 20px;
  }
}

@media (max-width: 480px) {
  .reported-section td {
    padding-left: 40%; /* Adjust padding for smaller screens */
  }

  .reported-section td::before {
    left: 5px;
    width: calc(40% - 10px); /* Adjust width for smaller screens */
  }

  .table-container {
    padding: 20px;
  }

  .pagination-info {
    display: none; /* Hide pagination info on small screens */
  }

  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 47px;
  }
}
