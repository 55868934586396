.side-panel {
  position: fixed;
  top: 0;
  left: 0;
  width: 250px;
  height: 100%;
  background-color: var(--side-panel-bg-color);
  /* color: var(--side-panel-text-color); */
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  border-right: 2px solid var(--side-panel-border-color);
  align-items: center;
  z-index: 999;
}

.sidebar .side-panel-header {
  display: flex;
  margin-bottom: 38px;
}

.logo {
  width: 198px;
  height: 49px;
  margin-right: 10px;
  cursor: pointer;
}

.logonames {
  width: 145px;
  height: 55px;
  cursor: pointer;
}

.side-panel-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

ul {
  list-style-type: none;
  padding: 0;
}

.side-panel-list {
  color: var(--side-panel-text-color);
  font-size: 13.6px;
  font-weight: 500;
  margin-right: 15px;
  transition: color 0.3s ease-in-out;
}

.side-panel-list.active {
  color: var(--side-panel-active-color);
}

.icons {
  width: 18px;
  height: 18px;
  margin: -1px 18px;
}

.sidePanelUl {
  display: grid;
  grid-gap: 28px;
  margin-right: -21px;
}

.flexList {
  display: flex;
  cursor: pointer;
  transition: padding 0.3s ease-in-out;
}

.flexList.active {
  border: 2px solid var(--side-panel-active-color);
  padding: 10px 0px;
  border-right: 7px solid var(--side-panel-active-color);
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
}

.settingsDropdown {
  list-style-type: none;
  padding: 0;
  margin: 0;
  border: 2px solid #ebebeb;
  margin-top: -28px;
  border-radius: 4px;
  display: grid;
  justify-content: center;
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.6s ease-out;
}

.settingsDropdown.open {
  max-height: 500px;
  /* Adjust this value as needed */
}

.flexList.active .settingsDropdown {
  display: block;
}

.logout-item {
  position: absolute;
  bottom: 25px;
}

.settingsDropdownItem {
  padding: 10px 0px;
  cursor: pointer;
  color: var(--side-panel-text-color);
  font-size: 13px;
  margin-top: -1px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

.settingsDropdownItem.active {
  background-color: var(--side-panel-hover-bg-color);
  border-radius: 13px;
  color: var(--side-panel-hover-text-color);
  margin-bottom: 5px;
  margin-top: 5px;
  width: 130px
}

.side-panel.open {
  transform: translateX(0);
  background-color: var(--side-panel-bg-color);
}

.close-icon {
  height: 26px;
  cursor: pointer;
  top: 10px;
  position: absolute;
  right: 9px;
  display: none;
}

@media (max-width: 1250px) {
  .side-panel {
    transform: translateX(-250px);
    /* Hide the side panel by default */
  }

  .side-panel.open {
    transform: translateX(0);
    /* Show the side panel when open */
  }

  .close-icon {
    display: block;
    /* Show close icon on small screens */
  }
}

.menu-icon {
  position: absolute;
  top: 29px;
  left: 12px;
  cursor: pointer;
}

.kjhhh {
  height: 22px;
}

.njhbvg {
  margin-right: 7px;
}

.side-panel-list.active {
  color: var(--side-panel-active-color);
}

.flexList.active {
  border: 2px solid var(--side-panel-active-color);
  padding: 10px 0px;
  border-right: 7px solid var(--side-panel-active-color);
  border-bottom-left-radius: 8px;
  border-top-left-radius: 8px;
}