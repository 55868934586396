.inner-popup {
  display: flex;
  flex-direction: column;
  justify-content: start;
  border-radius: 20px;
  background-color: white;
  height: 60vh;
  width: 50%;
}

.main-container-request {
  width: 100%;
  height: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
  gap: 10px;
}

.button-animate {
  transition: transform 0.3s ease-in-out;
}

.button-animate:active {
  transform: scale(0.9);
}
.left-request {
  width: 45%;
  height: 40vh;
  background-color: #ccc;
  border-radius: 20px;
  overflow: hidden;
}
.left-request > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.right-request {
  width: 55%;
  height: 40vh;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.comment-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid #ccc;
  border-radius: 10px;
  padding: 10px;
}

.comment-content {
  width: 85%;
}

.full-container {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  color: #cccccc;
}
.comment-user {
  display: flex;
  justify-content: start;
  gap: 10px;
  align-items: center;
}
.user {
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: #ccc;
}
.comment-user > p {
  font-size: 18px;
  font-weight: 600;
}

.comment-flag {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
}

.actual-comment {
  margin-top: 1vh;
  font-size: 17px;
}

.header-right {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
}
.report-button {
  padding: 6px 10px;
  display: flex;
  gap: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
  background-color: transparent;
  transition: transform 0.5s ease;
}
.report-button:hover {
  transform: scale(1.08);
}
.close-btn {
  transition: transform 0.5s ease;
}
.close-btn:hover {
  transform: scale(1.08);
}
.comment-input-container {
  display: flex;
  justify-content: end;
  align-items: center;
  margin-top: 1vh;
}

.main-input-comment {
  width: 53%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 15px;
}

.comment-input {
  width: 88%;
  padding: 15px 20px;
  border-radius: 10px;
  background-color: #f1f1f1;
  outline: none;
  border: 0px;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
}

.send-btn {
  border-radius: 100%;
  cursor: pointer;
  outline: none;
  border: 0px;
  background-color: #00ba8c;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 1800px) {
  .comment-user > p {
    font-size: 12px;
    font-weight: 600;
  }
  .user {
    height: 20px;
    width: 20px;
  }
  .actual-comment {
    font-size: 14px;
  }
}
