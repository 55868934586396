* {
  font-family: "Montserrat", sans-serif;
}
.studyPlanContainer {
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--background-color);
}

.studyPlanGrid {
  display: flex;
  justify-content: start;
  gap: 2rem;
  flex-wrap: wrap;
  width: 85%;
  padding: 2rem;
}

.day-entry {
  display: flex;
  flex-direction: column;
  width: 22%;
  position: relative;
  background-color: var(--card-background-color);
  font-family: "Montserrat", sans-serif;
  color: var(--card-text-color);
  border: 1px solid var(--card-border-color);
  border-radius: 10px;
  transition: opacity 0.3s;
}

.dayColumn {
  z-index: 1;
  background-color: var(--card-background-color);
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 43vh;
}

.button-grid {
  height: 43vh;
  width: 22%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.save-and-exit {
  width: 100%;
  border-radius: 10px;
  background-color: var(--side-panel-hover-bg-color);
  padding: 20px 10px;
  font-size: 1.05rem;
  font-weight: 600;
  color: var(--card-text-color);
  outline: none;
  border: 2px solid var(--card-border-color);
  transition: transform 0.5s ease;
  cursor: pointer;
}
.save-and-exit:hover {
  color: black;
  transform: scale(1.08);
}
.today {
  border-color: var(--secondary-color);
}

.dayHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  font-weight: 600;
  font-size: 20px;
  background-color: #00ba8c;
  color: var(--background-color);
}

.todayLabel {
  background-color: var(--secondary-color);
  color: var(--light-text-color);
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: fit-content;
  padding: 5px 8px;
  border: 1px solid #baf3db;
  padding-bottom: 10px;
  margin-bottom: -10px;
  position: absolute;
  top: -25px;
}

.manageButton {
  background-color: var(--side-panel-hover-bg-color);
  border: none;
  font-weight: 600;
  padding: 5px 10px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  color: var(--side-panel-hover-text-color);
  font-family: "Montserrat", sans-serif;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.manageButton:hover {
  background-color: #00ba8c;
  color: var(--light-text-color);
}

.subtestList {
  list-style-type: none;
  padding: 10px;
  margin: 0;
  height: 100%;
  overflow: auto;
}

.subtestItem {
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  gap: 10px;
  margin-top: 5px;
  padding: 8px;
  transition: transform 0.3s ease;
  border-bottom: 1px solid #ccc;
}

.subtestItem:hover {
  transform: scale(1.05);
}

.selected {
  border: 1px solid var(--secondary-color);
  border-radius: 12px;
  background: var(--side-panel-hover-bg-color);
}

.first-list-item {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 25%;
}

.subtestIcon {
  height: 60px;
  width: 60px;
}

.subtestIcon > img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.subtest-title {
  width: 70%;
  font-size: 24px;
}

.day-entry.overlay-applied .dayColumn {
  pointer-events: none;
  opacity: 0.5;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
  pointer-events: none;
  opacity: 1;
  transition: opacity 0.3s;
}

.edit-subtest {
  outline: none;
  font-size: 18px;
  background-color: transparent;
  border: 0;
  color: var(--dashboard-text-color);
}

.list-icons {
  justify-content: flex-end;
}

.subtestList::-webkit-scrollbar {
  width: 14px;
  border-radius: 18px;
}

.subtestList::-webkit-scrollbar-track {
  display: none;
}

.subtestList::-webkit-scrollbar-thumb {
  background: rgba(192, 192, 192, 0.556);
  border-radius: 18px;
  border: 3px solid var(--card-background-color);
}

.subtestList::-webkit-scrollbar-thumb:hover {
  background: rgba(160, 160, 160, 1);
}

@media screen and (max-width: 1800px) {
  .studyPlanContainer {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--background-color);
  }

  .studyPlanGrid {
    display: flex;
    justify-content: start;
    gap: 2rem;
    flex-wrap: wrap;
    width: 85%;
    padding: 2rem;
  }

  .day-entry {
    display: flex;
    flex-direction: column;
    width: 22%;
    position: relative;
    background-color: var(--card-background-color);
    font-family: "Montserrat", sans-serif;
    color: var(--card-text-color);
    border: 1px solid var(--card-border-color);
    border-radius: 10px;
    transition: opacity 0.3s;
  }

  .dayColumn {
    z-index: 1;
    background-color: var(--card-background-color);
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 43vh;
  }

  .today {
    border-color: var(--secondary-color);
  }

  .dayHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    font-weight: 600;
    font-size: 20px;
    background-color: #00ba8c;
    color: var(--background-color);
  }

  .todayLabel {
    background-color: var(--secondary-color);
    color: var(--light-text-color);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: fit-content;
    padding: 5px 8px;
    padding-bottom: 10px;
    margin-bottom: -10px;
    position: absolute;
    top: -25px;
  }

  .manageButton {
    background-color: var(--side-panel-hover-bg-color);
    border: none;
    font-weight: 600;
    padding: 5px 10px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    color: var(--side-panel-hover-text-color);
    font-family: "Montserrat", sans-serif;
  }

  .manageButton:hover {
    background-color: var(--secondary-color);
    color: var(--light-text-color);
  }

  .subtestList {
    list-style-type: none;
    padding: 10px;
    margin: 0;
    height: 100%;
    overflow: auto;
  }

  .subtestItem {
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    gap: 10px;
    margin-top: 5px;
    padding: 10px;
    transition: transform 0.3s ease;
  }

  .subtestItem:hover {
    transform: scale(1.05);
  }

  .selected {
    border: 1px solid var(--secondary-color);
    border-radius: 12px;
    background: var(--side-panel-hover-bg-color);
  }

  .first-list-item {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 25%;
  }

  .subtestIcon {
    height: 50px;
    width: 50px;
  }

  .subtestIcon > img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  .first-list-item > span {
    font-size: 20px;
  }

  .day-entry.overlay-applied .dayColumn {
    pointer-events: none;
    opacity: 0.5;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    pointer-events: none;
    opacity: 1;
    transition: opacity 0.3s;
  }

  .edit-subtest {
    outline: none;
    font-size: 18px;
    background-color: transparent;
    border: 0;
    color: var(--dashboard-text-color);
  }

  .list-icons {
    justify-content: flex-end;
  }

  .subtestList::-webkit-scrollbar {
    width: 14px;
    border-radius: 18px;
  }

  .subtestList::-webkit-scrollbar-track {
    display: none;
  }

  .subtestList::-webkit-scrollbar-thumb {
    background: rgba(192, 192, 192, 0.556);
    border-radius: 18px;
    border: 3px solid var(--card-background-color);
  }

  .subtestList::-webkit-scrollbar-thumb:hover {
    background: rgba(160, 160, 160, 1);
  }
}

@media screen and (max-width: 1600px) {
  .studyPlanContainer {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .studyPlanGrid {
    display: flex;
    justify-content: start;
    gap: 2rem;
    flex-wrap: wrap;
    width: 85%;
    padding: 2rem;
  }

  .day-entry {
    display: flex;
    flex-direction: column;
    width: 22%;
    position: relative;
  }

  .dayColumn {
    z-index: 1;
    background-color: var(--card-background-color);
    border: 1px solid var(--card-border-color);
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 43vh;
  }

  .today {
    border-color: var(--secondary-color);
  }

  .first-list-item > span {
    font-size: 16px;
  }

  .dayHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    font-weight: 600;
    font-size: 16px;
    background-color: #00ba8c;
    color: var(--background-color);
  }

  .todayLabel {
    background-color: var(--secondary-color);
    color: var(--light-text-color);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    width: fit-content;
    padding: 5px 8px;
    padding-bottom: 10px;
    margin-bottom: -10px;
    position: absolute;
    top: -25px;
  }

  .manageButton {
    background-color: var(--side-panel-hover-bg-color);
    border: none;
    font-weight: 600;
    padding: 5px 10px;
    font-size: 12px;
    border-radius: 5px;
    cursor: pointer;
    color: var(--side-panel-hover-text-color);
  }

  .manageButton:hover {
    background-color: var(--secondary-color);
    color: var(--light-text-color);
  }

  .subtestList {
    list-style-type: none;
    padding: 10px;
    margin: 0;
    overflow: auto;
  }

  .subtestItem {
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
    gap: 10px;
    margin-top: 5px;
    padding: 10px;
  }

  .selected {
    border: 2px solid var(--secondary-color);
    border-radius: 12px;
  }

  .first-list-item {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 25%;
  }

  .subtestIcon {
    height: 40px;
    width: 40px;
  }

  .subtestIcon > img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  .day-entry {
    position: relative;
    transition: opacity 0.3s;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    pointer-events: none;
    opacity: 1;
    transition: opacity 0.3s;
  }

  .day-entry.overlay-applied .dayColumn {
    pointer-events: none;
    opacity: 0.5;
  }

  .edit-subtest {
    outline: none;
    font-size: 18px;
    background-color: transparent;
    border: 0;
    color: var(--dashboard-text-color);
  }

  .list-icons {
    justify-content: flex-end;
  }

  .studyPlanGrid {
    width: 90%;
  }
}

@media screen and (max-width: 1180px) {
  .studyPlanGrid {
    width: 98%;
  }
}

@media screen and (max-width: 1180px) {
  .subtestList {
    padding: 10px 5px;
  }

  .studyPlanGrid {
    gap: 1rem;
  }
}

@media screen and (max-width: 1040px) {
  .day-entry {
    width: 30%;
  }
}

@media screen and (max-width: 800px) {
  .studyPlanGrid {
    padding: 2rem 1rem;
  }
}

@media screen and (max-width: 765px) {
  .studyPlanGrid {
    justify-content: center;
    gap: 2rem;
  }

  .day-entry {
    width: 40%;
  }
}

@media screen and (max-width: 600px) {
  .studyPlanGrid {
    justify-content: center;
    gap: 2rem;
  }

  .day-entry {
    width: 80%;
  }

  .dayColumn {
    height: 35vh;
  }
}
