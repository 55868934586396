.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-inner {
  background: var(--popup-bg-color);
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 600px;
}

.bgfd {
  margin-top: 30px;
}

.bito {
  padding: 12px 39px;
  margin: 10px 5px;
  cursor: pointer;
  border: none;
  border-radius: 60px;
  font-size: 16px;
}

.bito[type="submit"] {
  background: var(--submit-bg-color);
  border-radius: 60px;
  color: #fff;
  transition: background 0.3s;
}

.bito[type="submit"]:hover {
  background: var(--submit-hover-bg-color);
}

.bito[type="button"] {
  border-radius: 60px;
  background: #6c757d;
  color: #fff;
  transition: background 0.3s;
}

.bito[type="button"]:hover {
  background: #5a6268;
}

.editTextButton {
  margin: 10px;
  padding: 10px 20px;
  background: #005eb8;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-family: Montserrat;

}

.editTextButton:hover {
  background: #004080;
}