/* Common button animation */
.button-animate {
  transition: transform 0.3s ease-in-out;
}

.button-animate:active {
  transform: scale(0.9);
}

.submit:hover,
.editTextBujtton:hover,
.gfhfh:hover,
.kjshshn:hover,
.skoolcommunity:hover {
  transform: scale(1.08);
}

/* Header styles */
.header {
  width: 100%;
  height: 78px;
  background-color: var(--header-background-color);
  border-bottom: 2px solid var(--header-border-color);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 60px;
}

.back-btn-container {
  border-radius: 100%;
  height: 40px;
  width: 40px;
  padding: 5px;
  box-shadow: var(--box-shadow-color) 0px 7px 29px 0px;
}

.header-title {
  font-size: 44px;
  font-weight: 700;
  color: var(--text-color);
}

.iconing {
  margin-top: 10px;
  width: 19px;
  cursor: pointer;
}

.iconing1 {
  cursor: pointer;
  margin-right: 10px;
  width: 18px;
}

.someitmes {
  display: flex;
}

.iconing {
  cursor: pointer;
  margin-left: 16px;
}

.skoolcommunity {
  display: flex;
  justify-content: space-around;
  align-items: center;
  cursor: pointer;
  padding: 4px;
  border: 1px solid var(--border-color);
  width: 180px;
  height: 39px;
  border-radius: 12px;
  margin-left: 16px;
  position: relative;
  transition: transform 0.5s ease-in-out;
}


.skoolcommunity:hover .tooltip-text {
  visibility: visible;
  opacity: 1;
}

.skool {
  font-size: 13.8px;
  font-weight: 400;
  color: var(--text-color);
}

.group {
  width: 19px;
}

.tooltip-text {
  visibility: hidden;
  width: 140px;
  font-size: 13px;
  background-color: #000;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 15px 0;
  position: absolute;
  z-index: 1;
  top: 50px;
  /* Position the tooltip below the text */
  left: 50%;
  transform: translateX(-50%);
  /* Center the tooltip */
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-text::after {
  position: absolute;
  top: -5px;
  /* Position the arrow above the tooltip */
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent var(--tooltip-background-color) transparent;
}

@media screen and (max-width:506px) {
  .iconing {
    margin-top: 5px;
  }
}

@media screen and (max-width:370px) {
  .header {
    width: 100%;
    height: 78px;
    background-color: var(--header-background-color);
    border-bottom: 2px solid var(--header-border-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 37px;
  }

  .menu-icon {
    position: absolute;
    top: 29px;
    left: 6px;
    cursor: pointer;
  }

  .iconing {
    margin-top: 5px;
  }
}