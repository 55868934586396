@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,200;9..40,300;9..40,400;9..40,500;9..40,600;9..40,700&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");

:root {
  --background-color: #ffffff;
  --border-color: #d8d9da;
  --header-background-color: #ffffff;
  --header-border-color: #d8d9da;
  --side-panel-bg-color: #ffffff;
  --side-panel-text-color: #333333;
  --side-panel-border-color: #ececec;
  --side-panel-active-color: #00ba8c;
  --side-panel-hover-bg-color: #baf3db;
  --side-panel-hover-text-color: #575a5b;
  --dashboard-background-color: #f5f5f5;
  --dashboard-text-color: #000000;
  --card-background-color: #ffffff;
  --card-text-color: #000000;
  --primary-color: #005eb8;
  --primary-hover-color: #004080;
  --secondary-color: #00ba8c;
  --light-text-color: #ffffff;
  --medium-text-color: #ededed;
  --form-text-color: #626262;
  --form-border-color: #8e8e8e;
  --submit-bg-color: #00ba8c;
  --submit-hover-bg-color: #09623d;
  --modal-bg-color: #ffffff;
  --success-color: #22a06b;
  --popup-bg-color: #ffffff;
  --popup-text-color: #000000;
  --popup-border-color: #d8d9da;
  --popup-slider-color: #22a06b;
  --lab-border-color: #ececec;
  --lab-title-color: #000000;
  --lab-description-color: #000000;
  --edit-icon-color: #a2a2a2;
  --pagination-bg-color: #005eb8;
  --pagination-hover-bg-color: #004a91;
  --pagination-active-bg-color: #003366;
  --general-info-border-color: #ececec;
  --general-info-bg-color: #ffffff;
  --general-info-text-color: #000000;
  --desc-text-color: #575a5b;
  --statistics-card-border-color: #e0e0e0;
  --statistics-card-background-color: #ffffff;
  --statistics-card-text-color: #000000;
  --select-background-color: #ffffff;
  --select-border-color: #ccc;
  --select-text-color: #000000;
  --userlist-header-bg-color: #f2f2f2;
  --userlist-row-bg-color: #f2f2f2;
  --userlist-hover-shadow-color: rgba(0, 0, 0, 0.1);
  --pagination-button-bg-color: #00bb88;
  --pagination-button-text-color: #ffffff;
  --archive-img-shadow-color: #dbdbdb;
  --archive-img-background-color: #ffffff;
  --archive-img-text-color: #000000;
  --archive-item-border-color: #e4e2e2;
  --filter-box-background-color: #ffffff;
  --filter-box-border-color: #ccc;
  --filter-item-text-color: #000000;
  --promotion-title-color: #000000;
  --input-border-color: #e0e0e0;
  --characteristics-border-color: #ccc;
  --chart-background-color: #f0f0f0;
  --stat-details-text-color: #00ba8c;
  --statistics-section-background-color: #f9f9f9;
  --statistics-section-border-color: #e0e0e0;
  --statistics-section-slider-bg-color: #ccc;
  --statistics-section-slider-checked-bg-color: #00ba8c;
  --lessoncard-bg-color: #ffffff;
  --lessoncard-border-color: #ccc;
  --lessoncard-hover-shadow-color: #e9e6e6;
  --dot-color: #ccc;
  --dot-completed-color: #00ba8c;
  --done-button-bg-color: #4bce97;
  --done-button-hover-bg-color: #178859;
  --text-color: #000000;
  --secondary-text-color: #575a5b;
  --filter-dropdown-background: #ffffff;
  --filter-dropdown-border: #ccc;
  --card-border-color: #ccc;
  --card-new-badge-background: #22a06b;
  --card-image-background: #f0f0f0;
  --card-reply-icon-color: #007bff;
  --search-bar-background: #f0f0f0;
  --search-bar-border: #ccc;
  --search-bar-text-color: #a8a8a8;
  --medical-library-background: #ffffff;
  --library-input-background: #f1f1f1;
  --send-query-button-background: #00ba8c;
  --send-query-button-hover-background: #09623d;
  --new-badge-background: #00ba8c;
  --card-content-text-color: rgb(13, 13, 13);
  --hover-for-dashboard-button: #e5e5e5;
}

[data-theme="dark"] {
  --background-color: #000000;
  --border-color: #727272;
  --header-background-color: #333333;
  --header-border-color: #555555;
  --side-panel-bg-color: #333333;
  --side-panel-text-color: #ffffff;
  --side-panel-border-color: #555555;
  --side-panel-active-color: #00ba8c;
  --side-panel-hover-bg-color: #444444;
  --side-panel-hover-text-color: #cccccc;
  --dashboard-background-color: #222222;
  --dashboard-text-color: #ffffff;
  --card-background-color: #444444;
  --card-text-color: #ffffff;
  --primary-color: #004080;
  --primary-hover-color: #003060;
  --secondary-color: #007060;
  --light-text-color: #ffffff;
  --medium-text-color: #bbbbbb;
  --form-text-color: #cccccc;
  --form-border-color: #7b7979;
  --submit-bg-color: #007060;
  --submit-hover-bg-color: #005040;
  --modal-bg-color: #333333;
  --success-color: #00ba8c;
  --popup-bg-color: #333333;
  --popup-text-color: #ffffff;
  --popup-border-color: #555555;
  --popup-slider-color: #007060;
  --lab-border-color: #555555;
  --lab-title-color: #ffffff;
  --lab-description-color: #cccccc;
  --edit-icon-color: #bbbbbb;
  --pagination-bg-color: #004080;
  --pagination-hover-bg-color: #003060;
  --pagination-active-bg-color: #002040;
  --general-info-border-color: #555555;
  --general-info-bg-color: #333333;
  --general-info-text-color: #ffffff;
  --desc-text-color: #cccccc;
  --statistics-card-border-color: #555555;
  --statistics-card-background-color: #333333;
  --statistics-card-text-color: #ffffff;
  --select-background-color: #444444;
  --select-border-color: #555555;
  --select-text-color: #ffffff;
  --userlist-header-bg-color: #555555;
  --userlist-row-bg-color: #444444;
  --userlist-hover-shadow-color: rgba(255, 255, 255, 0.1);
  --pagination-button-bg-color: #007060;
  --pagination-button-text-color: #ffffff;
  --archive-img-shadow-color: #555555;
  --archive-img-background-color: #333333;
  --archive-img-text-color: #ffffff;
  --archive-item-border-color: #555555;
  --filter-box-background-color: #333333;
  --filter-box-border-color: #555555;
  --filter-item-text-color: #ffffff;
  --promotion-title-color: #ffffff;
  --input-border-color: #555555;
  --characteristics-border-color: #555555;
  --chart-background-color: #444444;
  --stat-details-text-color: #007060;
  --statistics-section-background-color: #444444;
  --statistics-section-border-color: #555555;
  --statistics-section-slider-bg-color: #555555;
  --statistics-section-slider-checked-bg-color: #007060;
  --lessoncard-bg-color: #333333;
  --lessoncard-border-color: #555555;
  --lessoncard-hover-shadow-color: #555555;
  --dot-color: #555555;
  --dot-completed-color: #007060;
  --done-button-bg-color: #007060;
  --done-button-hover-bg-color: #005040;
  --text-color: #ffffff;
  --secondary-text-color: #cccccc;
  --filter-dropdown-background: #444444;
  --filter-dropdown-border: #555555;
  --card-border-color: #555555;
  --card-new-badge-background: #007060;
  --card-image-background: #555555;
  --card-reply-icon-color: #00bb88;
  --search-bar-background: #444444;
  --search-bar-border: #555555;
  --search-bar-text-color: #cccccc;
  --medical-library-background: #333333;
  --library-input-background: #555555;
  --send-query-button-background: #007060;
  --send-query-button-hover-background: #005040;
  --new-badge-background: #007060;
  --card-content-text-color: #ffffff;
  --hover-for-dashboard-button: #242424;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Montserrat", sans-serif;
  background-color: var(--background-color);
  color: var(--dashboard-text-color);
  transition: background-color 0.3s, color 0.3s;
}

.app-container {
  background-color: var(--background-color);
  color: var(--dashboard-text-color);
}
