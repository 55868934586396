.archiveItem {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 40px;
}

.pagination-button {
  background-color: var(--pagination-button-bg-color);
  border: none;
  color: var(--pagination-button-text-color);
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
}

.pagination-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.pagination-info {
  font-size: 14px;
}

.archiveImg {
  box-shadow: 0 0 4px var(--archive-img-shadow-color);
  border-radius: 8px;
  padding: 19px;
  width: 110px;
  height: 110px;
  background-color: var(--archive-img-background-color);
}

.daily {
  font-size: 24px;
  color: var(--archive-img-text-color);
  font-weight: 600;
}

.date {
  font-size: 16px;
  color: var(--desc-text-color);
  margin-top: 7px;
}

.exp {
  font-size: 16px;
  color: var(--desc-text-color);
  margin-top: 7px;
}

.archiveList {
  display: grid;
  grid-gap: 40px;
  padding: 70px 70px;
}

.archiveText {
  margin-left: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.star {
  width: 20px;
  height: 19px;
}

.oiu {
  display: flex;
  align-items: center;
}

.star1 {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
  padding: 0em 2em;
}

.starfilter {
  display: flex;
  position: relative;
  width: fit-content;
  gap: 1rem;
}

.gvgvh {
  width: 110px;
  padding: 11px;
  border-radius: 10px;
  background-color: var(--background-color);
  border: 2px solid var(--archive-item-border-color);
  display: flex;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.5s ease-in-out;
}

.gvgvh:hover {
  transform: scale(1.08);
  /* Scale up on hover */
}

/* .gvgvh:active {
  transform: scale(0.9);
} */

.kuu {
  font-size: 14px;
  font-weight: 500;
  color: var(--archive-img-text-color);
  margin: 5px 7px;
  letter-spacing: 0.5px;
}

.bagr {
  margin-top: 5px;
  width: 20px;
}

.filter-box {
  position: absolute;
  top: 105%;
  left: -37%;
  border-radius: 20px;
  border-top-right-radius: 0px;
  width: 11rem;
  padding: 1em;
  border: 1px solid var(--filter-box-border-color);
  background-color: var(--filter-box-background-color);
  z-index: 1;
  transition: ease-in 0.5s;
}

.filter-item {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 0.75rem;
  padding: 10px;
  cursor: pointer;
  color: var(--filter-item-text-color);
}

.frame {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-top: 1px;
  border: 2px solid var(--archive-item-border-color);
  padding: 11px 13px;
  border-radius: 8px;
  transition: transform 0.5s ease-in-out;
}

.frame:hover {
  transform: scale(1.08);
}

.frame:active {
  transform: scale(0.9);
}

.star-scale {
  transition: transform 0.3s ease-in-out;
}

.star-scale:hover {
  transform: scale(1.1);
}

.frame1 {
  cursor: pointer;
  margin-top: 1px;
}

@media screen and (max-width: 449px) {
  .archiveList {
    display: grid;
    grid-gap: 40px;
    padding: 47px 19px;
  }

  .daily {
    font-size: 13px;
    color: var(--archive-img-text-color);
    font-weight: 600;
  }

  .exp {
    font-size: 12px;
    color: var(--desc-text-color);
    margin-top: 7px;
  }

  .date {
    font-size: 11px;
    color: var(--desc-text-color);
    margin-top: 7px;
  }

  .archiveImg {
    box-shadow: 0 0 4px var(--archive-img-shadow-color);
    border-radius: 8px;
    padding: 16px;
    width: 100px;
    height: 91px;
    background-color: var(--archive-img-background-color);
  }
}