.popupOver3lay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.popupwContent {
    background: var(--popup-bg-color);
    padding: 23px 38px;
    border-radius: 21px;
    width: 552px;
    position: relative;
    color: var(--popup-text-color);
    border: 1px solid var(--popup-border-color);
}

.closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    font-size: 26px;
    cursor: pointer;
    border-radius: 70px;
    padding: 0px 8px;
    color: var(--popup-text-color);
}

.hano {
    margin-bottom: 30px;
    font-weight: 600;
    font-family: "Montserrat", sans-serif;
    color: var(--popup-text-color);
}

.popusphTithle {
    font-size: 21px;
    margin-bottom: 20px;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    color: var(--popup-text-color);
}

.pospupBody {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
}

.entryItem {
    padding: 15px;
    background: var(--background-color);
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    display: grid;
    grid-gap: 12px;
}

.mgggg {
    margin-top: 4px;
}

.mkjnjjj {
    font-weight: 500;

}

.entryResult {
    font-size: 16px;
    margin-bottom: 10px;
    color: var(--entry-result-color);
}

.pagination {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.paginationButton {
    padding: 10px 20px;
    background: var(--submit-bg-color);
    color: var(--light-text-color);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-family: "Montserrat", sans-serif;
    transition: background-color 0.3s, color 0.3s;
}

.paginationButton:disabled {
    background: var(--disabled-bg-color);
    cursor: not-allowed;
}

.paginationButton:hover:not(:disabled) {
    background: var(--submit-hover-bg-color);
}