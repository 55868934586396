.statisticsSection {
  display: flex;
  flex-direction: column;
  padding: 50px;
  color: var(--dashboard-text-color);
}

.statisticsSection h2 {
  margin-bottom: 20px;
}

.statisticsSection h1 {
  font-size: 2rem;
  margin-bottom: 20px;
}

.statisticsGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px 30px;
  margin-top: 20px;
}

.statItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 26px 19px;
  background: var(--statistics-section-background-color);
  border-radius: 5px;
  border: 1px solid var(--statistics-section-border-color);
}

.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--statistics-section-slider-bg-color);
  transition: 0.4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: var(--background-color);
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: var(--statistics-section-slider-checked-bg-color);
}

input:checked + .slider:before {
  transform: translateX(14px);
}

@media screen and (max-width: 644px) {
  .statisticsGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 40px 30px;
    margin-top: 20px;
  }
}

@media screen and (max-width: 452px) {
  .statisticsGrid {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 40px 30px;
    margin-top: 20px;
  }
}
