.login-page {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
}

.loginContainer {
  padding-top: 4px;
  text-align: center;
}

.loginsys {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 60px;
  transition: opacity 1s ease-in-out;
}

.blur-background {
  filter: blur(5px);
  pointer-events: none;
  /* Prevent interaction with the blurred content */
}

.logos {
  display: flex;
  justify-content: center;
  width: 100%;
  transition: transform 0.5s ease-in-out;
}

.logo-animation {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  animation: zoomInOut 3s ease-in-out forwards;
}

@keyframes zoomInOut {
  0% {
    transform: scale(0);
  }
  50% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

.medgang {
  width: 300px;
  transition: transform 0.5s ease-in-out;
}

.login_form {
  width: 100%;
  max-width: 636px;
  margin: 0 auto;
  padding: 21px 50px;
  border-radius: 24px;
  background-color: var(--card-background-color);
  display: grid;
  justify-content: center;
  box-shadow: 0 0 9px var(--border-color);
  margin-bottom: 50px;
  transition: opacity 1s ease-in-out;
}

.login_form.show {
  opacity: 1;
}

.welcome_back {
  text-align: center;
  font-size: 33px;
  font-weight: 500;
  margin-bottom: 20px;
}

.lkjh {
  font-size: 16px;
  font-weight: 500;
  color: var(--text-color);
  display: flex;
}

.ooii[type="email"],
.ooii[type="password"],
.ooii[type="text"] {
  width: 441px;
  height: 46px;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid var(--form-border-color);
  border-radius: 10px;
  outline: none;
  font-family: "Montserrat", sans-serif;
  background-color: var(--background-color);
  color: var(--form-text-color);
}

.submitbutton {
  width: 100%;
  display: flex;
  justify-content: center;
  font-family: "Montserrat", sans-serif;
}

.submit {
  width: 174px;
  height: 48px;
  padding: 10px;
  margin: 10px 0;
  border: none;
  border-radius: 60px;
  background-color: var(--submit-bg-color);
  color: var(--light-text-color);
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  font-family: "Montserrat", sans-serif;
}

.submit:hover {
  background-color: var(--submit-hover-bg-color);
  transform: scale(1.08);
}

.submit.pressed {
  transform: scale(0.9);
}

.check-login {
  display: flex;
  gap: 8px;
  padding: 5px 0px;
  align-items: center;
}

.checkbox-style {
  width: 20px;
  height: 18px;
  appearance: none;
  background-color: white;
  border: 2px solid var(--form-border-color);
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: border-color 0.5s ease, background-size 0.5s ease-in-out;
}

/* .checkbox-style:checked {
  background-color: white;
  border: none;
} */

.checkbox-style:checked {
  background-image: url('../assets/icon/check.svg'); /* This will use the SVG */
  background-size: contain;  /* Ensures the image fits the checkbox */
  background-repeat: no-repeat;
  background-position: center;  /* Centers the image in the checkbox */
  background-size: 20px 20px;
  filter: grayscale(100%);
}

/* .checkbox-style:checked::after {
  content: "✓"; 
  font-size: 14px;
  font-weight: bold;
  color: black;
} */

.checkbox-style:hover {
  border-color: var(--submit-hover-bg-color);
}

.toggle_form {
  font-size: 14px;
  margin-top: 10px;
  margin-bottom: 5px;
  cursor: pointer;
}

.switch-container {
  display: flex;
  justify-content: start;
  gap: 1rem;
  align-items: center;
}

@media screen and (max-width: 584px) {
  .ooii[type="email"],
  .ooii[type="password"],
  .ooii[type="text"] {
    width: 227px;
    height: 46px;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid var(--form-border-color);
    border-radius: 10px;
    outline: none;
  }

  .medgang {
    width: 220px;
    margin-top: 40px;
  }

  .login_form {
    width: 100%;
    max-width: 636px;
    margin: 0 auto;
    padding: 17px 26px;
    border-radius: 24px;
    background-color: var(--card-background-color);
    display: grid;
    justify-content: center;
    box-shadow: 0 0 9px var(--border-color);
    margin-bottom: 50px;
  }
}
