.generalInfo {
  width: 561px;
  height: 125px;
  display: flex;
  align-items: center;
  padding: 0px 30px;
  border: 2px solid var(--general-info-border-color);
  border-radius: 15px;
  margin-top: 50px;
  background-color: var(--general-info-bg-color);
  color: var(--general-info-text-color);
}

.info {
  display: grid;
  margin-left: 14px;
}

.general {
  font-size: 20px;
  color: var(--general-info-text-color);
  font-weight: 600;
  margin-top: 20px;
}

.desc {
  font-size: 18px;
  color: var(--desc-text-color);
  margin-top: 20px;
}

.testcont {
  display: grid;
  justify-content: center;
}

.generalInfoHeader {
  display: flex;
}

.edihhtIcon {
  color: var(--edit-icon-color);
  cursor: pointer;
  margin-left: 174px;
}

.editIcgon {
  color: var(--edit-icon-color);
  cursor: pointer;
}

@media screen and (max-width: 587px) {
  .general {
    font-size: 15px;
    color: var(--general-info-text-color);
    font-weight: 600;
  }

  .desc {
    font-size: 14px;
    color: var(--desc-text-color);
    margin-top: 15px;
  }

  .generalInfo {
    width: 360px;
    height: 98px;
  }

  .editIcon {
    margin-left: 140px;
    color: var(--edit-icon-color);
    cursor: pointer;
  }
}

@media screen and (max-width: 390px) {
  .general {
    font-size: 13px;
    color: var(--general-info-text-color);
    font-weight: 600;
  }

  .desc {
    font-size: 12px;
    color: var(--desc-text-color);
    margin-top: 15px;
  }

  .generalInfo {
    width: 282px;
    height: 98px;
  }

  .editIcon {
    margin-left: 93px;
    color: var(--edit-icon-color);
    cursor: pointer;
  }

  .error {
    width: 30px;
  }
}
