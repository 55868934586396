.studyPlan {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 80vh;
    gap: 2rem;
}

.view-btn {
    padding: 21px 67px;
    border-radius: 12px;
    border: 1px solid #ccc;
    font-weight: 600;
    font-size: 22.5px;
    text-align: center;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    font-family: "Montserrat", sans-serif;
    transition: transform 0.5s ease-in-out;
}


.view-btn:hover {
    transform: scale(1.08);
}

.info-icon {
    color: gray;
    transition: 0.3s ease-in-out;
}

.info-icon:hover {
    color: black;
}



@media screen and (max-width: 1000px) {
    .studyPlan {
        display: grid;
        grid-template-columns: auto auto;
        grid-gap: 107px;
        padding: 85px 66px;
        margin-top: 37px;
    }
}

@media screen and (max-width: 678px) {
    .studyPlan {
        display: grid;
        grid-template-columns: auto;
        grid-gap: 97px;
        padding: 85px 0px;
        margin-top: 37px;
        justify-content: center;
    }
}