.textLessonContainer {
  padding: 20px;
}

.backButton {
  background: transparent;
  border: 0;
  cursor: pointer;
}

/* styles/labLesson.css */

.headerContainer {
  position: relative;
}

.backButton {
  position: relative;
}

.backButton .tooltip {
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s;
}

.backButton:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.testName {
  flex-grow: 1;
  text-align: center;
  font-size: 24px;
  margin: 0;
}

.markComplete {
  display: flex;
  align-items: center;
}

.markComplete input {
  margin-right: 5px;
}

.oihbhu {
  padding: 84px;
}

.oiuyoi {
  font-size: 30px;
}

.iuyt {
  font-size: 16px;
  line-height: 2;
  margin-top: 15px;
}

.iuyt ol {
  margin-left: 18px;
}

.cxcv {
  margin-top: 24px;
  list-style-type: disc;
  padding-left: 20px;
}

.cvbnm {
  margin-bottom: 10px;
}

.nextButton1 {
  background-color: var(--submit-bg-color);
  color: var(--light-text-color);
  border: none;
  border-radius: 91px;
  padding: 30px 22px;
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: 18px;
  transition: background-color 0.3s, color 0.3s;
}

.nextButton1:hover {
  background-color: var(--submit-hover-bg-color);
}

.editetextt {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.notext {
  text-align: center;
  font-weight: 600;
  font-size: 25px;
  color: var(--text-color);
}

.addTextButton {
  background-color: var(--submit-bg-color);
  color: var(--light-text-color);
  border: none;
  border-radius: 91px;
  padding: 10px 22px;
  cursor: pointer;
  font-size: 17px;
  margin-top: 26px;
  transition: background-color 0.3s, color 0.3s;
}

.addTextButton:hover {
  background-color: var(--submit-hover-bg-color);
}

.bhjkj {
  display: flex;
  justify-content: center;
  width: 100%;
}

.bhjkj1 {
  display: grid;
  justify-content: center;
  margin-top: 22px;
}

.loijuh {
  padding: 16px 80px;
}

.contextText {
  display: flex;
  justify-content: center;
  margin-top: 280px;
}

.contextText1 {
  display: grid;
  justify-content: center;
  margin-top: 200px;
}

.or {
  margin-top: 20px;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  text-align: center;
}

@media screen and (max-width: 530px) {
  .loijuh {
    padding: 0px 13px;
  }
}

@media screen and (max-width: 330px) {
  .testName {
    flex-grow: 1;
    text-align: center;
    font-size: 15px;
    margin: 0;
  }

  .loijuh {
    padding: 0px 13px;
  }

  .headerContainer {
    /* position: relative; */
    padding-bottom: 15px;
  }

  .backk {
    width: 38px;
  }

  .courseName {
    flex-grow: 1;
    text-align: center;
    font-size: 19px;
    margin: 0;
  }
}
