.register-page {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  position: relative;
}

.register-container {
  padding-top: 4px;
  text-align: center;
  width: 100%;
}

.error-message {
  color: red;
  padding-bottom: 8px;
}

.register-system {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 60px;
  transition: opacity 1s ease-in-out;
}

.register-form {
  width: 600px;
  max-width: 636px;
  margin: 0 auto;
  padding: 21px 50px;
  border-radius: 24px;
  background-color: var(--card-background-color);
  display: grid;
  justify-content: center;
  box-shadow: 0 0 9px var(--border-color);
  margin-bottom: 50px;
}

.register-title {
  text-align: center;
  font-size: 33px;
  font-weight: 500;
  margin-bottom: 20px;
}

.register-label {
  font-size: 16px;
  font-weight: 500;
  color: var(--text-color);
  display: flex;
  align-items: center;
}

.register-input[type="email"],
.register-input[type="password"],
.register-input[type="text"] {
  width: 500px;
  height: 46px;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid var(--form-border-color);
  border-radius: 10px;
  outline: none;
  font-family: "Montserrat", sans-serif;
  background-color: var(--background-color);
  color: var(--form-text-color);
}

.register-button-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.register-button {
  width: 50%;
  max-width: 174px;
  height: 48px;
  padding: 10px;
  margin: 4px 0;
  border: none;
  border-radius: 60px;
  background-color: var(--submit-bg-color);
  color: var(--light-text-color);
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.register-button:hover {
  background-color: var(--submit-hover-bg-color);
}

.generate-password-text {
  cursor: pointer;
  margin-top: 2px;
  text-decoration: underline;
  color: green;
  padding: 5px 0;
}

.input-group {
  position: relative;
  display: flex;
  align-items: center;
}

.register-input {
  flex-grow: 1;
  margin-right: -30px;
}

.toggle-password {
  position: absolute;
  right: 25px;
  cursor: pointer;
  color: #ccc;
  display: flex;
  align-items: center;
  height: 100%; 
}

@media screen and (max-width: 1024px) {
  .input-group .toggle-password {
    right: 15px !important;
  }
}

@media screen and (max-width: 768px) {
  .input-group .toggle-password {
    right: 10px !important;
  }
}
@media screen and (max-width: 768px) {
  .input-group {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
  }

  .toggle-password {
    right: 80px;
  }
}

.switch-container {
  display: flex;
  justify-content: space-between; /* Align items to the end on larger screens */
}

@media screen and (max-width: 768px) {
  .switch-container {
    flex-direction: column; /* Stack items in a column on smaller screens */
    align-items: flex-start; /* Align items to the start in the column layout */
  }
}

@media screen and (max-width: 584px) {
  .register-input {
    width: 100%;
    height: 46px;
    padding: 20px;
    margin: 10px 0;
    border: 1px solid var(--form-border-color);
    border-radius: 10px;
    outline: none;
  }

  .register-input[type="email"],
  .register-input[type="password"],
  .register-input[type="text"] {
    width: 300px;
  }

  .register-form {
    padding: 20px;
    width: 350px;
  }

  .register-title {
    font-size: 28px;
  }

  .medgang {
    width: 180px;
  }

  .register-container {
    padding: 20px;
  }
}
