.popup1 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-inner {
  background: var(--popup-bg-color);
  padding: 20px;
  border-radius: 5px;
  text-align: center;
}

.bito {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 20px;
  margin: 10px;
  cursor: pointer;
}

.bito:hover {
  background-color: #2980b9;
}

.formVideo {
  display: grid;
  justify-content: center;
}

.bitonBtn {
  display: flex;
  width: 100%;
  justify-content: center;
}

.input_video {
  cursor: pointer;
}

.loader-video {
  width: 15px;
  height: 15px;
  border: 3px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
