.courseName {
  flex-grow: 1;
  text-align: center;
  font-size: 31px;
  margin: 0;
}

.figmaDesignContainer {
  padding: 20px;
}

.backButton {
  background: transparent;
  border: 0;
  cursor: pointer;
}

.jjj {
  flex-grow: 1;
  text-align: center;
  margin: 0;
  margin-top: 10px;
}

/* styles/labLesson.css */

.headerContainer {
  position: relative;
}

.backButton {
  position: relative;
}

.backButton .tooltip {
  position: absolute;
  top: -5px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #333;
  color: #fff;
  padding: 5px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s;
}

.backButton:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.pageTitle {
  text-align: center;
  margin: 20px 0;
}

.figmaDesignContent {
  display: flex;
  padding: 50px;
}

.opooiij {
  display: flex;
}

.characteristics h2,
.facts h2,
.completion h2,
.newExerciseEntry h3,
.course h3,
.sectionNa h3,
.chart h2 {
  margin-bottom: 20px;
}

.chartContent {
  height: 200px;
  background: var(--chart-background-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}

.characteristics {
  border: 1px solid var(--characteristics-border-color);
  width: 349px;
  padding: 18px;
  border-radius: 20px;
}

.ppoo {
  display: grid;
  width: 100%;
  grid-gap: 13px;
}

.poiuii {
  display: grid;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  margin-left: 40px;
}

.ghjkj {
  color: var(--light-text-color);
  background: var(--secondary-color);
  margin: -30px -18px;
  padding: 15px 15px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin-bottom: 15px;
}

.hello {
  font-size: 16px;
  color: var(--secondary-color);
  font-weight: 600;
  margin-top: 14px;
  letter-spacing: 0.5px;
}

.hello1,
.hello12 {
  font-size: 16px;
  color: var(--dashboard-text-color);
  font-weight: 600;
  overflow-x: auto;
  letter-spacing: 0.5px;
  margin-top: 15px;
}

/* Hide scrollbar for Webkit browsers */
.hello1::-webkit-scrollbar,
.hello12::-webkit-scrollbar {
  width: 0px;
  background: transparent;
  /* Optional: just to be sure it is transparent */
}

.hello12 {
  margin-left: 24px;
}

.hello121 {
  font-size: 16px;
  color: var(--dashboard-text-color);
  font-weight: 600;
  margin-top: 24px;
  letter-spacing: 0.5px;
  line-height: 30px;
}

.facts {
  width: 480px;
  height: 150px;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--characteristics-border-color);
  border-radius: 20px;
  padding: 15px 71px;
  display: grid;
}

.factscard {
  width: 480px;
  margin-left: 54px;
}

.jhgfdfgh {
  text-align: center;
}

.completion {
  width: 480px;
  height: 150px;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--characteristics-border-color);
  border-radius: 20px;
  margin-top: 44px;
  background-color: var(--secondary-color);
  color: var(--light-text-color);
  padding: 46px 185px;
}

.numberf {
  width: 144px;
  background-color: #fff;
  padding: 11px;
  border-radius: 10px;
  border: 1px solid #d8d9da;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.kuu {
  font-size: 18px;
  font-weight: 500;
  color: var(--archive-img-text-color);
  margin: 5px 7px;
  letter-spacing: 0.5px;
  font-family: "Montserrat", sans-serif;
}

.newExerciseEntry {
  width: 330px;
  height: 234px;
  background-color: var(--secondary-color);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  cursor: pointer;
  transition: transform 0.3s ease-in;
}

.newExerciseEntry:hover {
  box-shadow: 0 0 5px var(--secondary-color);
  transform: scale(1.08);
}

.button-animate {
  transition: transform 0.3s ease-in-out;
}

.button-animate:active {
  transform: scale(0.9);
}

.newExerciseEntry1,
.newExerciseEntry2 {
  width: 330px;
  height: 249px;
  background-color: var(--background-color);
  border-radius: 20px;
  display: grid;
  justify-content: center;
  align-items: center;
  padding: 3px 40px;
  cursor: pointer;
  border: 1px solid var(--characteristics-border-color);
  transition: transform 0.3s ease-in;
  margin-top: 17px;
}

.newExerciseEntry1:hover,
.newExerciseEntry2:hover {
  box-shadow: 0 0 5px var(--medium-text-color);
  transform: scale(1.08);
}

.hhggvbgv {
  margin-left: 105px;
  width: 146px;
}

.newexc {
  color: var(--light-text-color);
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 23px;
}

.chagrsrt {
  width: 860px;
  height: 478px;
}

.cioiuu {
  font-size: 31px;
  color: var(--dashboard-text-color);
  font-weight: 700;
}

.nhgfcdx {
  font-size: 26px;
  color: var(--light-text-color);
  font-weight: 700;
  margin-top: -5px;
}

.kgtfnj {
  text-align: center;
  margin-top: 14px;
}

.jdhcabhb {
  font-size: 48px;
  margin-top: -25px;
  font-weight: 700;
  width: 200px;
}

.noCharacteristics {
  margin-top: 101px;
  text-align: center;
  font-size: 14px;
}

.hhhbh {
  font-size: 15px;
  color: var(--dashboard-text-color);
  font-weight: 600;
  margin-top: 12px;
  letter-spacing: 0.5px;
}

.mkjhg {
  font-size: 18px;
  color: var(--dashboard-text-color);
  font-weight: 500;
  margin-top: 8px;
  margin-bottom: 29px;
}

.mnbi {
  display: flex;
  margin-top: 20px;
}

.lkjhjj {
  display: grid;
}

.lkjuu {
  display: grid;
}

.backk {
  transition: transform 0.3s ease;
  border-radius: 70px;
}

.backk:hover {
  transform: scale(1.08);
}

.button-animate {
  transition: transform 0.3s ease-in-out;
}

.button-animate:active {
  transform: scale(0.9);
}

/* Add these styles to your subtestOverview.css file */

/* Styles for the edit popup */
.popnnup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.poiuyuii {
  display: flex;
  justify-content: center;
  width: 100%;
}

.popup-innhher {
  background: var(--popup-bg-color);
  padding: 20px;
  border-radius: 8px;
  width: 540px;
  max-width: 80%;
  max-height: 80%;
  overflow-y: auto;
}

.popupTnitle {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
  color: var(--popup-text-color);
}

.editForm .formGroup {
  margin-bottom: 25px;
  margin-top: 10px;
}

.formLabel {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: var(--popup-text-color);
}

.formInput {
  width: 100%;
  padding: 8px;
  border: 1px solid var(--popup-border-color);
  border-radius: 4px;
  background-color: var(--background-color);
  color: var(--dashboard-text-color);
}

.formActions {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
}

.savgeButton,
.cancejlButton {
  padding: 12px 35px;
  border: none;
  border-radius: 70px;
  cursor: pointer;
}

.savgeButton {
  background-color: var(--secondary-color);
  color: var(--light-text-color);
  transition: background-color 0.3s, color 0.3s;
}

.savgeButton:hover {
  background-color: var(--submit-hover-bg-color);
}

.cancejlButton {
  background-color: #f44336;
  color: var(--light-text-color);
  transition: background-color 0.3s, color 0.3s;
}

.cancejlButton:hover {
  background-color: #a9180e;
}

/* Styles for the edit button */
.editButtonContainer {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.editButton {
  background-color: #007bff;
  color: var(--light-text-color);
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  margin-left: 220px;
}

/* Override the default styles of the slick arrows */
.slick-prev:before,
.slick-next:before {
  color: var(--light-text-color);
  font-size: 30px;
  background-color: var(--secondary-color);
  border-radius: 60px;
}

/* Optional: Add background to arrows for better visibility */
.slick-prev,
.slick-next {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--secondary-color);
}

/* Add this to your subtestOverview.css file */
.newExerciseEntry:hover .tooltip,
.newExerciseEntry1:hover .tooltip,
.newExerciseEntry2:hover .tooltip,
.medi-card:hover .tooltip {
  display: block;
}

.medi-card .tooltip {
  display: none;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  z-index: 1;
  top: 80%;
  right: 0%;
  width: 160px;
}

.newExerciseEntry .tooltip,
.newExerciseEntry1 .tooltip,
.newExerciseEntry2 .tooltip {
  display: none;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  color: #fff;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  margin-left: -78px;
  width: 160px;
}

.newExerciseEntry {
  position: relative;
}

.newExerciseEntry1 {
  position: relative;
}

.newExerciseEntry2 {
  position: relative;
}

.medi-card {
  position: relative;
}

.kjhghhnn {
  width: 860px;
  height: 478px;
}

@media screen and (max-width: 1250px) {
  .factscard {
    width: 340px;
  }

  .completion {
    width: 340px;
    height: 150px;
    border-radius: 10px;
    margin-top: 44px;
    background-color: var(--secondary-color);
    color: var(--light-text-color);
    padding: 46px 109px;
  }

  .jhgfdfgh {
    text-align: center;
    font-size: 15px;
  }
}

@media screen and (max-width: 1134px) {
  .factscard {
    width: 351px;
  }

  .completion {
    width: 340px;
    height: 150px;
    border-radius: 10px;
    margin-top: 44px;
    background-color: var(--secondary-color);
    color: var(--light-text-color);
    padding: 46px 109px;
  }

  .jhgfdfgh {
    text-align: center;
    font-size: 15px;
  }

  .poiuii {
    display: grid;
    width: 100%;
    justify-content: center;
    flex-direction: column;
  }

  .iuyht {
    width: 100%;
    display: grid;
    justify-content: center;
  }

  .ppoo {
    display: grid;
    width: 100%;
    justify-content: center;
    grid-gap: 30px;
  }

  .chagrsrt {
    width: 404px;
    height: 302px;
  }
}

@media screen and (max-width: 462px) {
  .factscard {
    width: 245px;
  }

  .overview-row {
    flex-direction: column;
  }

  .left-overview-container {
    width: 100%;
  }

  .right-overview-container {
    width: 100%;
  }

  .completion {
    width: 265px;
    height: 137px;
    border-radius: 10px;
    margin-top: 43px;
    background-color: var(--secondary-color);
    color: var(--light-text-color);
    padding: 39px 77px;
  }

  .jhgfdfgh {
    text-align: center;
    font-size: 15px;
  }

  .poiuii {
    display: grid;
    width: 100%;
    justify-content: center;
    flex-direction: column;
  }

  .iuyht {
    width: 100%;
    display: grid;
    justify-content: center;
  }

  .ppoo {
    display: grid;
    width: 100%;
    justify-content: center;
    grid-gap: 30px;
  }

  .newExerciseEntry {
    width: 265px;
    height: 218px;
    background-color: var(--secondary-color);
    border-radius: 20px;
    display: grid;
    justify-content: center;
    align-items: center;
    padding: 8px;
    cursor: pointer;
    transition: transform 0.3s ease-in;
    margin-top: 0px;
  }

  .newexc {
    color: var(--light-text-color);
    font-size: 17px;
    font-weight: 700;
  }

  .newExerciseEntry1,
  .newExerciseEntry2 {
    width: 241px;
    height: 215px;
    background-color: var(--background-color);
    border-radius: 20px;
    display: grid;
    justify-content: center;
    align-items: center;
    padding: 20px;
    cursor: pointer;
    border: 1px solid var(--characteristics-border-color);
    transition: transform 0.3s ease-in;
  }

  .chagrsrt {
    width: 274px;
    height: 222px;
  }

  .characteristics {
    border: 1px solid var(--characteristics-border-color);
    width: 267px;
    padding: 18px;
    border-radius: 10px;
  }

  .hello {
    font-size: 11px;
    color: var(--secondary-color);
    font-weight: 600;
    letter-spacing: 0.5px;
  }

  .hello12 {
    font-size: 11px;
    color: var(--dashboard-text-color);
    font-weight: 600;
    margin-top: 12px;
    letter-spacing: 0.5px;
    margin-left: 16px;
  }

  .editButton {
    background-color: #007bff;
    color: var(--light-text-color);
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 179px;
  }

  .cioiuu {
    font-size: 26px;
    color: var(--dashboard-text-color);
    font-weight: 700;
  }

  .mkjhg {
    font-size: 15px;
    color: var(--dashboard-text-color);
    font-weight: 500;
    margin-top: 13px;
  }

  .figmaDesignContent {
    display: grid;
    gap: 20px;
    padding: 40px 0px;
  }
}

.chart-container {
  padding: 20px;
  border: 1px solid lightgray;
  border-radius: 20px;
  background-color: var(--background-color);
  width: 100%;
  max-width: 900px;
  margin-bottom: 10px;
}

.chart-wrapper {
  height: 450px;
}

@media (max-width: 600px) {
  .chart-container {
    padding: 10px;
  }

  .chart-wrapper {
    height: 300px;
  }
}

.stat-toggler {
  height: 50px;
  width: 120px;
  cursor: pointer;
  margin-right: 10px;
}

.stat-toggler-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.stat-details {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 80px;
  justify-content: space-around;
  gap: 10px;
  padding: 20px;
  color: var(--stat-details-text-color);
  font-size: 1.5rem;
}

.staisticsCard {
  flex: 1;
  max-width: 320px;
  margin: 10px;
  border-radius: 15px;
  border-top: 1px solid var(--statistics-card-border-color);
  border-left: 1px solid var(--statistics-card-border-color);
  border-right: 1px solid var(--statistics-card-border-color);
  border-bottom: 1px solid var(--statistics-card-border-color);
  background-color: var(--statistics-card-background-color);
  color: var(--statistics-card-text-color);
}

.fgj {
  padding: 20px;
}

.wo {
  font-size: 16px;
  font-weight: 400;
}

.hr {
  font-size: 40px;
  font-weight: 700;
  margin-top: 20px;
}

@media (max-width: 768px) {
  .stat-details {
    flex-direction: row;
    gap: 5px;
  }

  .staisticsCard {
    font-size: 0.5rem;
    width: 80%;
    margin: 2px 0;
  }
}

.completionCard {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  height: 150px;
  margin-top: 25px;
}

.progressBackground {
  background-color: #00ba8c;
  height: 100%;
  display: flex;
  /* padding: 20px; */
  border-radius: 20px;
}

.completionText {
  display: grid;
  justify-content: space-between;
  width: 100%;
  color: white;
  margin: 14px 19px;
}

.kjhjjnnmb {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
}

.factText {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  max-height: 100px;
  overflow-y: auto;
}

@media screen and (max-width: 1361px) {
  .figmaDesignContent {
    display: grid;
    padding: 50px;
    justify-content: center;
  }

  .ppoo {
    display: grid;
    width: 100%;
    grid-gap: 13px;
    justify-content: center;
  }

  .iuyht {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .newExerciseEntry1,
  .newExerciseEntry2 {
    width: 276px;
    height: 234px;
    margin-top: 0px;
    margin-left: 10px;
  }

  .newExerciseEntry {
    width: 276px;
    height: 234px;
  }

  .poiuii {
    margin-left: 0px;
  }

  .opooiij {
    display: flex;
    justify-content: center;
  }

  .chart-container {
    max-width: 723px;
  }

  .newexc {
    color: var(--light-text-color);
    font-size: 27px;
    font-weight: 700;
  }

  .cioiuu {
    font-size: 27px;
    color: var(--dashboard-text-color);
    font-weight: 700;
  }

  .mkjhg {
    font-size: 14px;
    color: var(--dashboard-text-color);
    font-weight: 500;
    margin-top: 12px;
    margin-bottom: 24px;
  }
}

@media screen and (max-width: 947px) {
  .figmaDesignContent {
    display: grid;
    padding: 50px;
    justify-content: center;
  }

  .ppoo {
    display: grid;
    width: 100%;
    grid-gap: 13px;
    justify-content: center;
  }

  .iuyht {
    display: grid;
    justify-content: center;
    width: 100%;
    grid-gap: 11px;
  }

  .newExerciseEntry1,
  .newExerciseEntry2 {
    width: 276px;
    height: 234px;
    margin-top: 0px;
    margin-left: 0px;
  }

  .newExerciseEntry {
    width: 276px;
    height: 234px;
  }

  .poiuii {
    margin-left: 0px;
  }

  .opooiij {
    display: grid;
    width: 100%;
    justify-content: center;
  }

  .chart-container {
    max-width: 351px;
  }

  .newexc {
    color: var(--light-text-color);
    font-size: 27px;
    font-weight: 700;
  }

  .cioiuu {
    font-size: 27px;
    color: var(--dashboard-text-color);
    font-weight: 700;
  }

  .mkjhg {
    font-size: 14px;
    color: var(--dashboard-text-color);
    font-weight: 500;
    margin-top: 12px;
    margin-bottom: 24px;
  }

  .factscard {
    margin-left: 0px;
    margin-top: 25px;
  }
}

@media screen and (max-width: 447px) {
  .figmaDesignContent {
    display: grid;
    padding: 22px;
    justify-content: center;
  }

  .ppoo {
    display: grid;
    width: 100%;
    grid-gap: 13px;
    justify-content: center;
  }

  .iuyht {
    display: grid;
    justify-content: center;
    width: 100%;
    grid-gap: 11px;
  }

  .newExerciseEntry1,
  .newExerciseEntry2 {
    width: 235px;
    height: 197px;
    padding: 0px 5px;
    margin-top: 0px;
    margin-left: 0px;
  }

  .newExerciseEntry {
    width: 235px;
    height: 197px;
    padding: 0px;
  }

  .poiuii {
    margin-left: 0px;
  }

  .opooiij {
    display: grid;
    width: 100%;
    justify-content: center;
  }

  .chart-container {
    max-width: 243px;
  }

  .hhggvbgv {
    margin-left: 45px;
    width: 130px;
  }

  .newexc {
    color: var(--light-text-color);
    font-size: 18px;
    font-weight: 700;
  }

  .cioiuu {
    font-size: 18px;
    color: var(--dashboard-text-color);
    font-weight: 700;
  }

  .mkjhg {
    font-size: 14px;
    color: var(--dashboard-text-color);
    font-weight: 500;
    margin-top: 12px;
    margin-bottom: 24px;
  }

  .factscard {
    margin-left: 0px;
    margin-top: 25px;
  }

  .hello121 {
    font-size: 10px;
    font-weight: 600;
    margin-top: 24px;
    letter-spacing: 0.5px;
    line-height: 24px;
  }

  .characteristics {
    width: 240px;
  }

  .editButton {
    background-color: #007bff;
    color: var(--light-text-color);
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 149px;
  }

  .courseName {
    flex-grow: 1;
    text-align: center;
    font-size: 15px;
    margin-left: -30px;
  }

  .kjkhjuuo {
    font-size: 14px;
    text-align: center;
  }
}
