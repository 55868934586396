.labCards {
  display: grid;
  justify-content: center;
  grid-template-columns: auto auto auto auto;
  grid-gap: 80px 33px;
  margin-top: 90px;
}

.button-animate {
  transition: transform 0.3s ease-in-out;
}

.button-animate:active {
  transform: scale(0.9);
}

/* For hover effect */
.card:hover {
  transform: scale(1.08);
}

.labCards {
  display: grid;
  justify-content: center;
  grid-template-columns: auto auto auto auto;
  grid-gap: 80px 33px;
  margin-top: 90px;
}

/* Card styles */
.card {
  transition: transform 0.3s ease;
}


.labCards:hover .tooltip {
  visibility: visible;
  opacity: 1;
}

.labbox {
  border: 2px solid var(--lab-border-color);
  border-radius: 15px;
  padding: 36px 42px;
  cursor: pointer;
  background-color: var(--card-background-color);
  color: var(--lab-title-color);
  position: relative;
}

.labTitle {
  font-size: 19px;
  color: var(--lab-title-color);
  font-weight: 700;
  overflow-wrap: break-word;
  text-wrap: wrap;
  display: flex;
  gap: 0.25rem;
  align-items: center;
  position: relative;
  margin-left: -22px;
}

.description {
  font-size: 13.5px;
  font-weight: 500;
  width: 160px;
  color: var(--lab-description-color);
  margin-left: -20px;
}

.editIcon {
  margin-left: 189px;
  color: var(--edit-icon-color);
  cursor: pointer;
}

.cardimg {
  margin-top: -101px;
  margin-left: -35px;
  border-radius: 15px;
  padding: 4px;
  width: 140px;
  height: 140px;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
  /* Full height to cover the entire screen */
}

.addSkillButton {
  background-color: var(--submit-bg-color);
  color: var(--light-text-color);
  padding: 14px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  width: 106px;
  margin-top: 20px;
  transition: background-color 0.3s, color 0.3s;
}

.addSkillButton:hover {
  background-color: var(--submit-hover-bg-color);
  /* Darker shade for hover */
}

.uuiiii {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.page-number {
  background-color: var(--pagination-bg-color);
  color: var(--light-text-color);
  border: none;
  padding: 10px 15px;
  margin: 0 5px;
  cursor: pointer;
  font-size: 14px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.page-number:hover {
  background-color: var(--pagination-hover-bg-color);
  /* Darker shade for hover */
}

.page-number.active {
  background-color: var(--pagination-active-bg-color);
  /* Different color for the active page */
}

.page-number:focus {
  outline: none;
}

@media screen and (max-width: 1069px) {
  .labCards {
    grid-template-columns: auto auto auto;
    margin-top: 119px;
  }
}

@media screen and (max-width: 800px) {
  .labCards {
    grid-template-columns: auto auto;
    margin-top: 119px;
  }
}

@media screen and (max-width: 526px) {
  .labCards {
    grid-template-columns: auto;
    margin-top: 119px;
  }

  .addSkillButton {
    padding: 12px;
    width: 80px;
  }

  .labbox {
    padding: 20px;
  }
}

.tooltip {
  visibility: hidden;
  font-size: 13px;
  width: 120px;
  /* Adjust width as necessary */
  background-color: var(--lab-title-color);
  color: var(--card-background-color);
  text-align: center;
  border-radius: 6px;
  padding: 8px 0;
  position: absolute;
  z-index: 1;
  bottom: 145%;
  /* Position the tooltip above the text */
  left: 50%;
  transform: translateX(-50%);
  transition: opacity 0.5s;
}

.tooltip::after {
  position: absolute;
  top: 100%;
  /* At the bottom of the tooltip */
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
}