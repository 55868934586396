.popupOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popupContent {
  background: var(--popup-bg-color);
  padding: 12px 13px;
  border-radius: 21px;
  width: 734px;
  position: relative;
  color: var(--popup-text-color);
  border: 1px solid var(--popup-border-color);
}

.closeButton {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  font-size: 26px;
  cursor: pointer;
  border-radius: 70px;
  padding: 0px 8px;
  color: var(--popup-text-color);
}

.popuphTithle {
  font-size: 21px;
  margin-bottom: 13px;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  color: var(--popup-text-color);
}

.popupBody {
  display: flex;
  flex-direction: column;
  gap: 39px;
  margin-top: 40px;
  padding: 0px 144px;
}

.nbhvgvjjj {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  padding: 0px 142px;
}

.lkjhgii {
  display: grid;
}

.popupBqody {
  display: grid;
  margin-top: 40px;
  justify-content: center;
  grid-gap: 20px;
}

.njkhgfv {
  display: flex;
  justify-content: space-between;
}

.jkhghhj {
  display: grid;
}

.formGroup1 {
  display: grid;
}

.lkjhg .kjhjjj {
  font-size: 12px;
  padding: 12px 13px;
  border: 1px solid var(--form-border-color);
  border-radius: 6px;
  outline: none;
  font-family: "Montserrat", sans-serif;
  background: var(--background-color);
  color: var(--form-text-color);
}

.formGroupw {
  display: grid;
  margin-bottom: 23px;
}

.previogusButton {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  background: var(--submit-bg-color);
  color: var(--light-text-color);
  font-family: "Montserrat", sans-serif;
  transition: background-color 0.3s, color 0.3s;
}

.lkjhjj {
  display: grid;
}

.lkjhgg {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.submitgButton {
  padding: 13px 26px;
  border: none;
  border-radius: 42px;
  cursor: pointer;
  background: var(--submit-bg-color);
  color: var(--light-text-color);
  transition: background-color 0.3s, color 0.3s, transform 0.3s ease-in-out;
  font-family: "Montserrat", sans-serif;
}

.submitgButton:hover {
  background: var(--submit-hover-bg-color);
  transform: scale(1.08);
}

.submitgButton[disabled] {
  background: #cccccc;
  cursor: not-allowed;
  position: relative;
}

.submitgButton[disabled]::after {
  content: attr(data-tooltip);
  position: absolute;
  background: rgba(0, 0, 0, 0.75);
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  top: -30px;
  /* Adjust position as needed */
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  z-index: 10;
  font-size: 12px;
  display: none;
}

.submitgButton[disabled]:hover::after {
  display: block;
}

.nexthhButton {
  background: var(--submit-bg-color);
  color: var(--light-text-color);
  align-self: center;
  outline: none;
  border: 0;
  padding: 13px 49px;
  border-radius: 60px;
  cursor: pointer;
  font-size: 15px;
  transition: background-color 0.3s, color 0.3s, transform 0.3s ease-in-out;
  font-family: "Montserrat", sans-serif;
}

.nexthhButton:hover {
  background: var(--submit-hover-bg-color);
  transform: scale(1.08);
}

.previogusButton {
  padding: 13px 26px;
  border: none;
  border-radius: 42px;
  cursor: pointer;
  background: transparent;
  color: var(--submit-bg-color);
  border: 2px solid var(--submit-bg-color);
  transition: transform 0.3s ease-in-out;
  font-family: "Montserrat", sans-serif;
}

.previogusButton:hover {
  transform: scale(1.08);
}

.submitButton {
  background: var(--submit-bg-color);
  color: var(--light-text-color);
}

.buttonGroup {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 20px;
}

.emoji-tooltip {
  width: 50px;
  cursor: pointer;
  margin-top: 14px;
}

.labelentry {
  font-size: 20px;
  color: var(--popup-text-color);
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  width: 426px;
  letter-spacing: 0.4px;
  line-height: 27px;
  margin-top: 28px;
}

.labelentry1 {
  font-size: 20px;
  color: var(--popup-text-color);
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  width: 426px;
  letter-spacing: 0.4px;
  line-height: 27px;
  margin-top: 28px;
  width: 236px;
}

.lkjhg {
  display: grid;
  grid-gap: 35px;
  margin-top: 28px;
}

.lkjhg .entryjh {
  font-size: 20px;
  font-weight: 500;
  font-family: "Montserrat", sans-serif;
  color: var(--popup-text-color);
}

.hdbjbdc {
  font-size: 24px;
  font-weight: 700;
  color: var(--popup-text-color);
  font-family: "Montserrat", sans-serif;
}

.mhujhh {
  width: 435px;
  height: 76px;
  border-radius: 8px;
  border: 1px solid var(--form-border-color);
  padding: 13px 15px;
  margin-top: 10px;
  outline: none;
  background: var(--background-color);
  color: var(--form-text-color);
}

.editableLabel {
  font-size: 17px;
  color: var(--popup-text-color);
  font-weight: 600;
  border: 1px solid var(--form-border-color);
  padding: 6px;
  font-family: "Montserrat", sans-serif;
  background: var(--background-color);
}

@media screen and (max-width: 335px) {
  .mhujhh {
    width: auto;
    height: 76px;
    border-radius: 8px;
    border: 1px solid var(--form-border-color);
    padding: 0px 15px;
    margin-top: 15px;
    outline: none;
    background: var(--background-color);
    color: var(--form-text-color);
  }
}

.emoji-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.emoji {
  font-size: 40px;
}

.slider1 {
  outline: none;
  opacity: 0.7;
  transition: opacity 0.2s;
  height: 84px;
  width: 509px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.lllk {
  display: flex;
  justify-content: center;
  width: 100%;
}

.tooltip-texts {
  visibility: hidden;
  padding: 13px 20px;
  background-color: black;
  color: white;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 1;
  bottom: -40px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
  font-size: 12px;
  width: 200px;
  font-family: "Montserrat", sans-serif;
}

.emoji:hover .tooltip-texts {
  visibility: visible;
  opacity: 1;
}

.emoji {
  position: relative;
  font-size: 40px;
  cursor: pointer;
}

@media screen and (max-width: 754px) {
  .popupContent {
    background: var(--popup-bg-color);
    padding: 12px 13px;
    border-radius: 21px;
    width: 500px;
    position: relative;
    color: var(--popup-text-color);
    border: 1px solid var(--popup-border-color);
  }

  .nbhvgvjjj {
    padding: 0px;
  }
}

@media screen and (max-width: 510px) {
  .popupContent {
    background: var(--popup-bg-color);
    padding: 12px 13px;
    border-radius: 21px;
    width: 321px;
    position: relative;
    color: var(--popup-text-color);
    border: 1px solid var(--popup-border-color);
  }

  .nbhvgvjjj {
    padding: 0px;
  }

  .labelentry1 {
    font-size: 14px;
  }

  .lkjhg .entryjh {
    font-size: 12px;
    font-weight: 500;
    font-family: "Montserrat", sans-serif;
    color: var(--popup-text-color);
  }

  .lkjhg .kjhjjj {
    font-size: 10px;
    padding: 5px 13px;
    border: 1px solid var(--form-border-color);
    border-radius: 6px;
    outline: none;
    font-family: "Montserrat", sans-serif;
    background: var(--background-color);
    color: var(--form-text-color);
    width: 70px;
  }

  .lkjhg {
    display: grid;
    grid-gap: 24px;
    margin-top: 28px;
    margin-left: -62px;
  }

  .nexthhButton {
    background: var(--submit-bg-color);
    color: var(--light-text-color);
    align-self: center;
    outline: none;
    border: 0;
    padding: 11px 35px;
    border-radius: 60px;
    cursor: pointer;
    font-size: 15px;
    transition: background-color 0.3s, color 0.3s, transform 0.3s ease-in-out;
    font-family: "Montserrat", sans-serif;
  }

  .popuphTithle {
    font-size: 18px;
    margin-bottom: 13px;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    color: var(--popup-text-color);
  }

  input[type="range"] {
    overflow: hidden;
    width: 300px;
    -webkit-appearance: none;
    background-color: #adadad;
    cursor: pointer;
    /* Ensure the cursor is a pointer when hovering over the slider */
  }

  input[type="range"]::-webkit-slider-thumb {
    width: 14px;
    /* Increase thumb size */
    height: 14px;
    /* Increase thumb size */
    -webkit-appearance: none;
    /* Make the thumb round */
    cursor: pointer;
    /* Change cursor to pointer */
    background: #484747;
    /* Thumb color */
    box-shadow: -300px 0 0 300px #00ba8c;
    /* Track color */
    transition: background-color 0.2s, box-shadow 0.2s;
    /* Smooth transition */
  }

  .mhujhh {
    width: 276px;
  }

  .labelentry {
    font-size: 12px;
    color: var(--popup-text-color);
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    width: 276px;
    letter-spacing: 0.4px;
    line-height: 27px;
    margin-top: 28px;
  }
}

.button-animate {
  transition: transform 0.3s ease-in-out;
}

.button-animate:active {
  transform: scale(0.9);
}
