.edit-popup-inner {
  display: flex;
  flex-direction: column;
  padding: 24px 10px 24px 10px;
  background-color: white;
  border-radius: 30px;
  width: 20%;
}
.edit-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 10px;
  padding-top: 0px;
  font-family: "Montserrat", sans-serif;
}

.second-field {
  display: flex;
  padding: 10px;
  align-items: start;
  flex-direction: column;
}
.input-container {
  padding: 10px 10px 10px 0px;
}

.second-field > input,
textarea {
  width: 100%;
  padding: 10px;
  border-radius: 10px;
  outline: none;
  font-family: "Montserrat", sans-serif;
  border: 2px solid #ccc;
}

.image-uploader {
  padding: 20px 20px;
  border-radius: 20px;
  border: 1px solid #ccc;
  width: 100%;
}

.disabled {
  cursor: not-allowed !important;
  opacity: 0.5;
}

.file-input-field {
  border-radius: 10px;
  border: 1px dashed #ccc;
  width: 100%;
  padding: 10px;
  margin-top: 2vh;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.file-input-field2 {
  border-radius: 10px;
  border: 1px dashed #ccc;
  width: 100%;
  padding: 10px;
  margin-top: 2vh;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.file-input-field > img {
  height: 100%;
  width: 25%;
}

.file-input-field2 > img {
  height: 50%;
  width: 20%;
}
.file-input {
  display: none; /* Hide the input field */
}
.temp-heading2 {
  color: rgb(58, 56, 56);
  font-weight: 600;
}
.temp-label {
  color: black;
  font-weight: 600;
}

.btn-container-edit {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 1vh;
  margin-top: 1vh;
}

.btn-submit {
  padding: 10px;
  width: 50%;
  border-radius: 30px;
  color: white;
  border: 0px;
  outline: none;
  background-color: #22a06b;
  cursor: pointer;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  transition: transform 0.3s ease-in-out;
}
.btn-submit:hover {
  transform: scale(1.08);
}

.button-animate {
  transition: transform 0.3s ease-in-out;
}

.button-animate:active {
  transform: scale(0.9);
}

.horizontal-line {
  width: 100%;
  height: 1px;
  background: #ccc;
  margin: 16px 0px;
}

.lib-upload-container {
  padding: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #cccccc;
}

.lib-upload-container > p {
  text-align: center;
  font-weight: 600;
}
@media screen and (max-width: 1800px) {
  .btn-container-edit {
    padding-bottom: 2vh;
  }
}

@media screen and (max-width: 1200px) {
  .edit-popup-inner {
    width: 30%;
  }
}
@media screen and (max-width: 1000px) {
  .edit-popup-inner {
    width: 40%;
  }
}
@media screen and (max-width: 700px) {
  .edit-popup-inner {
    width: 50%;
  }
}
@media screen and (max-width: 500px) {
  .edit-popup-inner {
    width: 70%;
  }
}
@media screen and (max-width: 350px) {
  .edit-popup-inner {
    width: 80%;
  }
}
